import { ZButton } from "@/shared/components/button";
import { ZCardBody } from "@/shared/components/card";
import { ZInput } from "@/shared/components/input";
import KeyIcon from "@/shared/icons/key-icon";
import GoogleIcon from "@/shared/images/google_logo.png";
import MicrosoftIcon from "@/shared/images/logos_microsoft-icon.svg";
import { addNotification } from "@/shared/states/notification";
import { useStytch } from "@/shared/states/stytch";
import { checkOrganizationName } from "@/shared/utils/organization-helper";
import parsePromise from "@/shared/utils/parsePromise";
import { ROUTES } from "@constants/routes";
import { ArrowLeft, ArrowRightIcon, Loader2 } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  setLoginStep,
  useDiscoveredOrganizations,
  useLoginDetails,
  useLoginStep,
} from "../../states/login";
import { LOGIN_STEP, ListOfOrganizationByEmail } from "../../types";
import { signInWithGoogleOauth } from "../../use-cases/google.use-case";
import { sendMagicLinkUseCase } from "../../use-cases/magic-link.use-case";
import { signInWithMicrosoftOauth } from "../../use-cases/microsoft.use-case";
import {
  createNewOrgUseCase,
  selectOrgUseCase,
} from "../../use-cases/organization.use-case";
import { getListOfOrganizationBasedOnEmail } from "../../use-cases/sso.use-case";
import LoginCard from "../login-card";
import { sessionDurationMins } from "../../constants";
import { cn } from "@/shared/utils/classname-merger";
import { ZTooltip } from "@/shared/components/ZTooltip/ZTooltip";
import { Tooltip } from "@nextui-org/react";
import AuthLayout from "../layout";

const Login = () => {
  const stytch = useStytch();
  const loginStep = useLoginStep();
  const [loading, setLoading] = useState(false);
  const [signingInWithGoogle, setSigningInWithGoogle] = useState(false);
  const [signingInWithMicrosoft, setSigningInWithMicrosoft] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [orgName, setOrgName] = useState("");
  const { organizationSlug } = useLoginDetails();
  const discoveredOrganizations = useDiscoveredOrganizations();

  const navigate = useNavigate();

  const onClickSendMagicLink = async () => {
    if (!email) {
      setError("Please enter a valid email address.");
      return;
    }
    // if (!isEmailAllowed(email)) {
    //   setError('Please enter a valid work email address.');
    //   return;
    // }
    try {
      setLoading(true);
      await sendMagicLinkUseCase({ stytch, email });
    } catch (e) {
      setError("An error occurred please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onClickSSOLogin = async () => {
    setLoading(true);
    const [error, data] = await parsePromise<string[]>(
      getListOfOrganizationBasedOnEmail(email),
    );
    setLoading(false);
    if (data && data.length > 0) {
      await stytch.sso.start({
        connection_id: data[0],
        login_redirect_url: `${window.location.origin}/auth`,
        signup_redirect_url: `${window.location.origin}/auth`,
      });
    } else {
      setError("There is no SAML SSO configured for this user");
    }

    if (error) {
      setError(error.message);
      addNotification({
        message: "Failed to get List Of Organization",
      });
    }
  };

  const onClickCreateOrg = async () => {
    if (!orgName || !checkOrganizationName(orgName, organizationSlug ?? "")) {
      return;
    }
    try {
      setLoading(true);
      await createNewOrgUseCase({ stytch, orgName });
    } catch (e) {
      setError("An error occurred please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onClickGoogleLogin = async () => {
    try {
      setSigningInWithGoogle(true);
      await signInWithGoogleOauth({ stytch });
    } catch (e) {
      setError("An error occurred please try again.");
    }
  };
  const onClickMicrosoftLogin = async () => {
    try {
      setSigningInWithMicrosoft(true);
      await signInWithMicrosoftOauth({ stytch });
    } catch (e) {
      setError("An error occurred please try again.");
      console.error(e);
    }
  };
  const navigateToCreateOrg = () => {
    if (discoveredOrganizations && discoveredOrganizations.length > 0) {
      setLoginStep(LOGIN_STEP.NEW_ORG);
    } else navigate(`/${ROUTES.SIGNUP}`);
  };

  const handleOrgSelect = async (organization_id: string) => {
    const session = stytch.session.getInfo();
    setLoading(true);
    if (
      session.session &&
      session.session.authentication_factors.length > 0 &&
      session.session.authentication_factors[0].type === "sso"
    ) {
      const [exchangeError] = await parsePromise(
        stytch.session.exchange({
          organization_id,
          session_duration_minutes: sessionDurationMins,
        }),
      );
      if (exchangeError) {
        console.error(exchangeError);
        setLoading(false);
        return;
      }
      setLoginStep(LOGIN_STEP.MAIN);
    } else {
      void selectOrgUseCase({
        stytch,
        orgId: organization_id,
      });
    }
  };

  const renderLoginStep = () => {
    switch (loginStep) {
      case LOGIN_STEP.MAIN:
        return (
          <LoginCard
            title="Get started"
            description={`We'll sign you in or create an account if you don't have one
                yet.`}
          >
            <div className="grid gap-2">
              <ZInput
                id="email"
                type="email"
                label="Work Email"
                placeholder="johndoe@email.com"
                required
                value={email}
                isInvalid={!!error}
                errorMessage={error}
                onChange={(e) => setEmail(e.target.value)}
                variant="faded"
              />
            </div>
            <ZButton
              className="w-full border-small"
              onClick={() => void onClickSendMagicLink()}
              isLoading={loading}
              isDisabled={signingInWithGoogle || loading}
            >
              {loading ? "Please Wait" : "Send Magic Link"}
            </ZButton>
            <div className="flex items-center ">
              <div className="flex-1 h-px bg-gray-200 dark:bg-gray-700" />
              <span className="mx-4 text-gray-500 dark:text-gray-400">or</span>
              <div className="flex-1 h-px bg-gray-200 dark:bg-gray-700" />
            </div>
            <div className="space-y-4">
              <ZButton
                className="w-full border-small p-4"
                variant="bordered"
                onClick={() => void onClickGoogleLogin()}
                isLoading={signingInWithGoogle}
                isDisabled={signingInWithGoogle || loading}
                {...(!signingInWithGoogle && {
                  startContent: (
                    <img
                      src={GoogleIcon}
                      alt="Google"
                      className="h-5 w-5 mr-2"
                    />
                  ),
                })}
              >
                {signingInWithGoogle ? "Please Wait" : "Continue with Google"}
              </ZButton>
              <ZButton
                className="w-full border-small p-4"
                variant="bordered"
                onClick={() => void onClickMicrosoftLogin()}
                isLoading={signingInWithMicrosoft}
                isDisabled={signingInWithMicrosoft || loading}
                {...(!signingInWithMicrosoft && {
                  startContent: (
                    <img
                      src={MicrosoftIcon}
                      alt="Google"
                      className="h-5 w-5 mr-2"
                    />
                  ),
                })}
              >
                {signingInWithMicrosoft
                  ? "Please Wait"
                  : "Continue with Microsoft"}
              </ZButton>
              <ZButton
                className="w-full border-small p-4"
                variant="bordered"
                onClick={() => setLoginStep(LOGIN_STEP.SSO)}
                {...(!signingInWithMicrosoft && {
                  startContent: <KeyIcon />,
                })}
              >
                {"Continue with SAML SSO"}
              </ZButton>
            </div>
          </LoginCard>
        );
      case LOGIN_STEP.EMAIL_SUCCESS:
        return (
          <LoginCard
            hideHeader
            title="Email Sent!"
            description="A magic link has been sent to your email. Click the link to login to your account."
          >
            <ZButton
              className="w-full"
              onClick={() => void onClickSendMagicLink()}
              isLoading={loading}
            >
              {loading ? "Please Wait" : "Resend Magic Link"}
            </ZButton>
          </LoginCard>
        );
      case LOGIN_STEP.NEW_ORG:
        return (
          <LoginCard
            title="Create new organization"
            description="You are not part of any organization. Create a new organization
                to continue."
          >
            <div className="grid gap-2">
              {/* <Label htmlFor="org-name">Organization Name</Label> */}
              <ZInput
                id="org-name"
                type="org-name"
                placeholder="Acme Inc."
                label="Organization Name"
                required
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
              />
            </div>
            <ZButton
              className="w-full"
              onClick={() => void onClickCreateOrg()}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Please Wait
                </>
              ) : (
                "Create Organization"
              )}
            </ZButton>
          </LoginCard>
        );
      case LOGIN_STEP.ERROR:
        return (
          <LoginCard
            title="Uh oh!"
            description="There was a error in logging you in. Please try again."
          >
            <ZButton
              className="w-full"
              onClick={() => setLoginStep(LOGIN_STEP.MAIN)}
            >
              Try Again
            </ZButton>
          </LoginCard>
        );
      case LOGIN_STEP.CONTACT_ADMIN:
        return (
          <LoginCard
            title="Contact Admin"
            description=" It seems that this organization already exists. Please contact the
              admin to get access."
          >
            <div className="flex items-center">
              <div className="flex-1 h-px bg-gray-200 dark:bg-gray-700" />
              <span className="mx-4 text-gray-500 dark:text-gray-400">or</span>
              <div className="flex-1 h-px bg-gray-200 dark:bg-gray-700" />
            </div>
            <ZCardBody className="grid gap-4">
              <ZButton className="w-full" onClick={navigateToCreateOrg}>
                Create new organization
              </ZButton>
            </ZCardBody>
          </LoginCard>
        );
      case LOGIN_STEP.ACCESS_RESTRICTED:
        return (
          <LoginCard
            title="Access Restricted!"
            description={
              'Contact <a href="mailto:sales@zania.ai" class="text-[#006FEE]">sales@zania.ai</a> to enable access to your organization.'
            }
          >
            <ZButton
              className="w-full"
              onClick={() => {
                setLoginStep(LOGIN_STEP.MAIN);
                stytch.session.revoke();
              }}
            >
              Back to Login
            </ZButton>
          </LoginCard>
        );
      case LOGIN_STEP.SELECT_ORG:
        return (
          <LoginCard hideHeader title="Select Organization">
            <div className="max-h-[640px] grid gap-6 overflow-auto">
              {discoveredOrganizations?.map(({ organization }) => {
                const SSOAvailable =
                  organization.sso_default_connection_id === null &&
                  stytch.session.getInfo().session?.authentication_factors[0]
                    .type === "sso";

                return (
                  <Tooltip
                    key={organization.organization_id}
                    closeDelay={0}
                    classNames={{
                      content:
                        "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny max-w-[600px]",
                      arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                    }}
                    content={
                      SSOAvailable ? (
                        <div className="py-2">
                          <p>SSO is not enabled for this organization.</p>
                          <p>Please contact your admin.</p>
                        </div>
                      ) : undefined
                    }
                    isDisabled={!SSOAvailable || loading}
                    placement="top"
                    showArrow={true}
                  >
                    <div
                      onClick={() => {
                        if (!loading)
                          handleOrgSelect(organization.organization_id);
                      }}
                      className={cn(
                        "flex w-full border-[1px] cursor-pointer border-border border-[#006FEE] text-[#006FEE] items-center justify-between rounded-lg bg-white  p-4 py-2 shadow-sm dark:bg-gray-950",
                        SSOAvailable &&
                        "border-[#A5A5A5] text-[#A5A5A5] bg-[#FCFCFC]",
                      )}
                    >
                      <div className="flex items-center ">
                        <div className="flex-shrink-0"></div>
                        <div>
                          <h3 className="text-base font-small">
                            {organization.organization_name}
                          </h3>
                        </div>
                      </div>
                      <div>
                        <ArrowRightIcon
                          className={cn(
                            "h-6 w-6 text-[#006FEE] dark:text-[#006FEE]",
                            SSOAvailable &&
                            "text-[#A5A5A5] dark:text-[#A5A5A5]",
                          )}
                        />
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </LoginCard>
        );
      case LOGIN_STEP.SSO: {
        return (
          <LoginCard
            title={
              <div className="flex gap-3">
                <div
                  className="cursor-pointer"
                  onClick={() => setLoginStep(LOGIN_STEP.MAIN)}
                >
                  <ArrowLeft />
                </div>
                <div className="font-semibold text-lg">
                  Continue with SAML SSO
                </div>
              </div>
            }
          // description="Enter your company's email domain to authenticate using SSO"
          >
            <div className="grid gap-2">
              <div className="grid gap-2">
                <ZInput
                  id="email"
                  type="email"
                  label="Work Email"
                  placeholder="johndoe@email.com"
                  required
                  value={email}
                  isInvalid={!!error}
                  errorMessage={error}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="faded"
                />
              </div>
            </div>
            <ZButton
              className="w-full"
              onClick={() => void onClickSSOLogin()}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Please Wait
                </>
              ) : (
                "Sign in with SAML"
              )}
            </ZButton>
          </LoginCard>
        );
      }
    }
  };

  return <AuthLayout>{renderLoginStep()}</AuthLayout>;
};

export default Login;
