import { post } from "@/infra/rest";
import { AgentSessionStepType } from "@/modules/sessions/types";
import { Control } from "../states/cusomize-controls";

export const refineControlsDescription = async (payload: RefineControlDescriptionPayload) => {
  try {
    const response = await post<RefineControlDescriptionPayload, RefineControlDescriptionResponse>({
      url: "/v0/rag/workfkows/soc2/controls/refine",
      data: payload,
    });

    return response;
  } catch (err) {
    throw new Error("Failed to refine Control");
  }
};

export const refineControlsTestingProcedure = async (payload: RefineControlDescriptionPayload) => {
  try {
    const response = await post<RefineControlDescriptionPayload, RefineControlTestingProcedureResponse>({
      url: "/v0/rag/workfkows/soc2/controls/refine",
      data: payload,
    });

    return response;
  } catch (err) {
    throw new Error("Failed to refine Control");
  }
};

interface RefineControlDescriptionPayload {
  task: AgentSessionStepType.SOC2_TYPE1 | AgentSessionStepType.SOC2_TYPE2;
  controls: Control[];
}

interface RefineControlDescriptionResponse {
  success: boolean;
  message: string;
  data: {
    controls: (Control & {
      old_control: string;
    })[];
  };
}

interface RefineControlTestingProcedureResponse {
  success: boolean;
  message: string;
  data: {
    controls: (Control & {
      old_toe: string;
      old_tod: string;
    })[];
  };
}
