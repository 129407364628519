import { ZButton } from "@/shared/components/button";
import ZBreadCrumbs from "@/shared/components/custom/breadcrumbs";
import { LoadingSpinner } from "@/shared/components/custom/spin";
import ErrorBoundary from "@/shared/components/error-boundary";
import {
  ZTableDeprecrated,
  type ZTableRowParams
} from "@/shared/components/table-deprecrated/TableDeprecrated";
import { ROUTES } from "@/shared/constants/routes";
import useQuery from "@/shared/hooks/use-query";
import { addNotification } from "@/shared/states/notification";
import { useStytch } from "@/shared/states/stytch";
import throttle from "@/shared/utils/throttle";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Spinner,
  Tab,
  Tabs,
  cn
} from "@nextui-org/react";
import { useStytchMember } from "@stytch/react/b2b";
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { ArrowRight, Download, Ellipsis } from "lucide-react";
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  frameworkTypeLabels,
  searchPlaceholderMap
} from "../constants";
import { getPaginatedAgentSessions } from "../requests";
import {
  type AgentSession,
  AgentSessionStatus,
  type AgentSessionStep,
  AgentSessionStepType,
  AgentSessionType,
  type AgentSessions,
  type AgentSessionsResponse,
  type DoraAgentSession,
  type MultiVendorAgentSession,
  type QaAgentSession,
  type RiskAgentSession,
  type SOC2GapAgentSession,
  type SOC2Type1GapAgentSession,
  type SOC2Type2NewGapAgentSession,
} from '../types';

import { generateAndDownloadSessionReport } from '@/modules/agent/utils/downloadReport';
import parsePromise from '@/shared/utils/parsePromise';
import clsx from "clsx";
import { getSessionColumns } from "../constants/columns";
import {
  handleContinueDoraGapAssessment,
  handleContinueMultiVendorAssessment,
  handleContinueQuestionnaire,
  handleContinueRiskAssessment,
  handleContinueSoc2GapAssessment,
} from '../use-cases/agent-session.use-case';
import { TablePagination } from "./TablePagination";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export const AgentSessionsHistory = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredAgentSessions, setFilteredAgentSessions] = useState<
    AgentSessions[]
  >([]);
  const [isAgentSessionsResuming, setIsAgentSessionsResuming] = useState<
    string | null
  >(null);
  // const [isAgentSessionsResuming, setIsAgentSessionsResuming] = useState<string | null>(null);
  // new state for tracking loading state per row
  const [loadingRows, setLoadingRows] = useState<Record<string, boolean>>({});
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<AgentSessionType>(AgentSessionType.RISK_ASSESSMENT);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const navigate = useNavigate();

  const stytch = useStytch();
  const { member } = useStytchMember();

  const columns = useMemo(() =>
    getSessionColumns(selectedTab, member?.member_id),
    [selectedTab, member?.member_id]
  );


  // Local state for sessions data
  const [sessionData, setSessionData] = useState<{
    sessions: AgentSessionsResponse["data"]["sessions"];
    totalSessions: number;
    totalPages: number;
  }>({
    sessions: [],
    totalSessions: 0,
    totalPages: 1
  });

  // Query with local state management
  const { isLoading: isAgentSessionsLoading } = useQuery({
    queryKey: ["agent-sessions", currentPage, rowsPerPage, selectedTab],
    queryFn: async () => {
      setIsTabLoading(true); // Set loading state at start of query
      try {
        const filters = {
          session_type: selectedTab === AgentSessionType.SOC2_GAP
            ? [
              AgentSessionType.SOC2_GAP,
              AgentSessionType.SOC2_GAP_TYPE1,
              AgentSessionType.SOC2_GAP_TYPE2
            ]
            : [selectedTab]
        };

        const { data } = await getPaginatedAgentSessions({
          page_number: currentPage,
          page_size: rowsPerPage,
          filters: filters
        });

        setSessionData({
          sessions: data.sessions,
          totalSessions: data.total_sessions,
          totalPages: data.total_pages
        });

        return data;
      } finally {
        setIsTabLoading(false);
        setIsPaginationLoading(false);
      }
    },
    refetchInterval: 60000
  });

  // Pagination handlers
  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
    setIsPaginationLoading(true);
  }, []);

  const handleRowsPerPageChange = useCallback((newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  }, []);



  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    const searchValue = e.target.value?.toLowerCase?.() ?? '';

    const filtered = sessionData.sessions.filter((agentSession) => {
      // Always search by session name
      const nameMatch = agentSession.session?.name
        ?.toLowerCase?.()
        ?.includes?.(searchValue);

      if (nameMatch) return true;

      // Type-specific searches
      switch (agentSession.session?.type) {
        case AgentSessionType.RISK_ASSESSMENT:
        case AgentSessionType.SOC2_GAP:
        case AgentSessionType.DORA_GAP:
          // Search by framework type
          return frameworkTypeLabels[agentSession.session.type]
            ?.toLowerCase?.()
            ?.includes?.(searchValue);

        case AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT:
          // Search by vendor product name
          return agentSession.session.multi_file_vendor_assessment?.vendor_name
            ?.toLowerCase?.()
            ?.includes?.(searchValue);

        case AgentSessionType.QA_FILLER:
          // Search by customer name
          return agentSession.session.name
            ?.toLowerCase?.()
            ?.includes?.(searchValue);

        default:
          return false;
      }
    });

    setFilteredAgentSessions(filtered);
  }, [sessionData.sessions]);

  const handleRiskAssessment = useCallback(
    async (session: RiskAgentSession, steps: AgentSessionStep[]) => {
      const [error] = await parsePromise(
        handleContinueRiskAssessment({ session, steps })
      );
      if (error) {
        console.error(error);
      }
    },
    []
  );

  const handleGapAssessment = useCallback(
    async (
      session:
        | DoraAgentSession
        | SOC2GapAgentSession
        | SOC2Type2NewGapAgentSession
        | SOC2Type1GapAgentSession,
      steps: AgentSessionStep[]
    ) => {
      try {
        switch (session.type) {
          case AgentSessionType.SOC2_GAP:
          case AgentSessionType.SOC2_GAP_TYPE1:
          case AgentSessionType.SOC2_GAP_TYPE2:
            await handleContinueSoc2GapAssessment({
              session,
              steps,
            });
            break;
          case AgentSessionType.DORA_GAP:
            await handleContinueDoraGapAssessment({
              session,
              steps,
            });
            break;
          default:
            throw new Error("Invalid session type for gap assessment");
        }
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const handleMultiVendorAssessment = useCallback(
    async (session: MultiVendorAgentSession, steps: AgentSessionStep[]) => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const [error] = await parsePromise(
        handleContinueMultiVendorAssessment({ session, steps })
      );

      if (error) {
        console.error(error);
      }
    },
    []
  );

  const handleContinueQaFiller = useCallback(
    async ({
      session,
      steps,
    }: {
      session: QaAgentSession;
      steps: AgentSessionStep[];
    }) => {
      const [error] = await parsePromise(
        handleContinueQuestionnaire({ session, steps })
      );

      if (error) {
        console.error(error);
      }
    },
    []
  );

  const handleDownloadReport = useCallback(
    async (session: AgentSession, steps: AgentSessionStep[]) => {
      if (
        isAgentSessionsLoading ||
        !!isAgentSessionsResuming ||
        loadingRows[session.id]
      ) {
        return;
      }
      // loading spinner
      setLoadingRows((prev) => ({ ...prev, [session.id]: true }));

      addNotification({
        type: "info",
        title: "Preparing to download",
        message: "Your request to download a report is being processed.",
      });
      await parsePromise(loadSessionResponseDataIntoStore(session, steps));
      await parsePromise(generateAndDownloadSessionReport(session.id));

      // loading spinner cleanup
      setLoadingRows((prev) => ({ ...prev, [session.id]: false }));
    },
    [isAgentSessionsLoading, isAgentSessionsResuming, loadingRows]
  );

  const loadSessionResponseDataIntoStore = useCallback(
    async (session: AgentSession, steps: AgentSessionStep[]) => {
      try {
        switch (session.type) {
          case AgentSessionType.RISK_ASSESSMENT:
            await handleRiskAssessment(session, steps);
            break;
          case AgentSessionType.QA_FILLER:
            await handleContinueQaFiller({ session, steps });
            break;
          case AgentSessionType.SOC2_GAP:
          case AgentSessionType.SOC2_GAP_TYPE1:
          case AgentSessionType.SOC2_GAP_TYPE2:
          case AgentSessionType.DORA_GAP:
            await handleGapAssessment(session, steps);
            break;
          case AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT:
            await handleMultiVendorAssessment(session, steps);
            break;
          default:
            throw new Error("Invalid session type");
        }
      } catch (error) {
        console.error(error);
        addNotification({
          type: "error",
          title: "Error",
          message: "Failed to continue session. Please try again.",
        });
      }
    },
    [
      handleRiskAssessment,
      handleContinueQaFiller,
      handleGapAssessment,
      handleMultiVendorAssessment,
    ]
  );

  const handleSessionContinuation = useCallback(
    async (session: AgentSession, steps: AgentSessionStep[]) => {
      if (
        isAgentSessionsLoading ||
        !!isAgentSessionsResuming ||
        loadingRows[session.id]
      ) {
        return;
      }

      // Special handling for multi-vendor assessment
      if (session.type === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT) {
        const completedSteps = steps.filter((step) => {
          const { status, type } = step;
          return (
            status === AgentSessionStatus.COMPLETE ||
            (type === AgentSessionStepType.MULTI_VENDOR_PROFILE &&
              (status === AgentSessionStatus.IN_PROGRESS ||
                status === AgentSessionStatus.INPUT_NEEDED))
          );
        }).length;

        if (!completedSteps) {
          return;
        }
      } else {
        // Original check for other assessment types
        const completedSteps = steps.filter(
          ({ status }) => status === AgentSessionStatus.COMPLETE
        ).length;

        if (!completedSteps) {
          return;
        }
      }

      // loading spinner
      setLoadingRows((prev) => ({ ...prev, [session.id]: true }));
      setIsAgentSessionsResuming(session.id);

      // load the json file, process it, and store it in the agentStore
      await loadSessionResponseDataIntoStore(session, steps);
      navigate(`/${ROUTES.AGENT}/${session.id}`);

      // loading spinner cleanup
      setLoadingRows((prev) => ({ ...prev, [session.id]: false }));
      setIsAgentSessionsResuming(null);
    },
    [
      navigate,
      loadSessionResponseDataIntoStore,
      isAgentSessionsLoading,
      isAgentSessionsResuming,
      loadingRows,
    ]
  );

  const handleSessionRowClick = useCallback(
    async ({ row }: ZTableRowParams<AgentSessions>) => {
      const { session, steps } = row;
      await handleSessionContinuation(session, steps);
    },
    [handleSessionContinuation]
  );



  const handleTabChange = (key: React.Key) => {
    setIsTabLoading(true);
    setSelectedTab(key as AgentSessionType);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <to reset the page number when the tab changes>
  useEffect(() => {

    // Reset pagination and search when tab changes
    setSessionData({
      sessions: [],
      totalSessions: 0,
      totalPages: 1,
    });
    setCurrentPage(1);
    setSearchTerm('');
    setFilteredAgentSessions([]);

    setLoadingRows({});
  }, [selectedTab]);

  return (
    <ErrorBoundary fallback={null}>
      <div className="w-full h-full flex flex-col">
        <div className="flex items-center pb-4 justify-between min-h-[44px] max-h-[44px]">
          <div className="flex gap-4 items-center">
            <ZBreadCrumbs
              items={[{ title: "Sessions", href: `/${ROUTES.AGENT}` }]}
            />
          </div>
          <ZButton
            disabled={isAgentSessionsLoading || !!isAgentSessionsResuming}
            onClick={() => navigate(`/${ROUTES.AGENT}`, { replace: false })}
            className="px-3 py-1 h-auto"
          >
            Start New Session
          </ZButton>
        </div>
        <div className="h-full w-full overflow-hidden rounded-lg flex flex-col">
          <div className="px-6 py-4 flex flex-col bg-white gap-2">
            {/* Below is the code for sessions header */}
            <div className="flex items-center grow justify-between w-full">
              <div className="flex items-center grow flex-1 gap-2">
                <p className="text-sm font-medium leading-5 text-[#171717]">
                  Sessions
                </p>

              </div>
              <div className="flex justify-center grow flex-1 items-center ">
                <div className="w-[612px]">
                  <Input
                    className="w-[612px] rounded-md bg-[#F4F4F5]"
                    placeholder={searchPlaceholderMap[selectedTab as keyof typeof searchPlaceholderMap]}
                    value={searchTerm}
                    disabled={
                      isAgentSessionsLoading || !!isAgentSessionsResuming
                    }
                    // NOTE: Use throttle as long as search is performed locally
                    // Use debounce if search is performed on the server via API
                    onChange={throttle(handleSearch, 200)}
                  />
                </div>
              </div>
              <div className="flex-1 overflow-auto relative" />
            </div>
            {/* End of the above code */}
          </div>
          <div className="grow overflow-hidden flex">
            <div className="flex-1 flex flex-col bg-white overflow-hidden">
              <div className="h-32px w-full">
                <Tabs
                  variant="underlined"
                  selectedKey={selectedTab}
                  classNames={{
                    tab: "rounded-none px-4 text-[12px] text-[#18181B]",
                    base: "rounded-none",
                    cursor: "rounded-none",
                    panel: "rounded-none",
                    tabContent: "rounded-none",
                    tabList: "rounded-none p-0",
                    wrapper: "rounded-none",
                  }}
                  className="border-b-1 bg-white w-158"
                  onSelectionChange={handleTabChange}
                  isDisabled={loadingRows[selectedTab]}
                >
                  <Tab
                    className={clsx("rounded-none", {
                      'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-t after:from-[#E7F2FF] after:to-[#FFFFFF] after:border-b-2 after:border-b-[#006FEE]':
                        selectedTab === AgentSessionType.RISK_ASSESSMENT,
                    })}
                    key={AgentSessionType.RISK_ASSESSMENT}
                    title={
                      <div className="flex items-center gap-2 ">
                        <span className="text-[#18181B]">Risk Assessment</span>
                        {selectedTab === AgentSessionType.RISK_ASSESSMENT && !isTabLoading && (
                          <div className="p-1 bg-[#F4F4F5] px-2 rounded-lg">
                            <p className="text-[10px] text-[#52525B] leading-4 font-medium">
                              {searchTerm ? filteredAgentSessions.length : sessionData.totalSessions ?? 0}
                            </p>
                          </div>
                        )}
                      </div>
                    }
                  />
                  <Tab
                    className={clsx("rounded-none", {
                      'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-t after:from-[#E7F2FF] after:to-[#FFFFFF] after:border-b-2 after:border-b-[#006FEE]':
                        selectedTab === AgentSessionType.SOC2_GAP
                    })}
                    key={AgentSessionType.SOC2_GAP}
                    title={
                      <div className="flex items-center gap-2 ">
                        <span className="text-[#18181B]">Gap Assessment</span>
                        {selectedTab === AgentSessionType.SOC2_GAP && !isTabLoading && (
                          <div className="p-1 bg-[#F4F4F5] px-2 rounded-lg">
                            <p className="text-[10px] text-[#52525B] leading-4 font-medium">
                              {searchTerm ? filteredAgentSessions.length : sessionData.totalSessions ?? 0}
                            </p>
                          </div>
                        )}
                      </div>
                    }
                  />
                  <Tab
                    className={clsx("rounded-none", {
                      'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-t after:from-[#E7F2FF] after:to-[#FFFFFF] after:border-b-2 after:border-b-[#006FEE]':
                        selectedTab === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT,
                    })}
                    key={AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT}
                    title={
                      <div className="flex items-center gap-2 ">
                        <span className="text-[#18181B]">Third-Party Risk Assessment</span>
                        {selectedTab === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT && !isTabLoading && (
                          <div className="p-1 bg-[#F4F4F5] px-2 rounded-lg">
                            <p className="text-[10px] text-[#52525B] leading-4 font-medium">
                              {searchTerm ? filteredAgentSessions.length : sessionData.totalSessions ?? 0}
                            </p>
                          </div>
                        )}
                      </div>
                    }
                  />
                  <Tab
                    className={clsx("rounded-none", {
                      'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-t after:from-[#E7F2FF] after:to-[#FFFFFF] after:border-b-2 after:border-b-[#006FEE]':
                        selectedTab === AgentSessionType.QA_FILLER,
                    })}
                    key={AgentSessionType.QA_FILLER}
                    title={
                      <div className="flex items-center gap-2 ">
                        <span className="text-[#18181B]">Security Questionnaire</span>
                        {selectedTab === AgentSessionType.QA_FILLER && !isTabLoading && (
                          <div className="p-1 bg-[#F4F4F5] px-2 rounded-lg">
                            <p className="text-[10px] text-[#52525B] leading-4 font-medium">
                              {searchTerm ? filteredAgentSessions.length : sessionData.totalSessions ?? 0}
                            </p>
                          </div>
                        )}
                      </div>
                    }
                  />
                </Tabs>
              </div>
              <div className="flex-1 flex flex-col overflow-hidden">
                <ZTableDeprecrated<AgentSessions>
                  className={cn(
                    "border-1 border-[#E4E4E7] overflow-auto scroll-auto h-full",
                  )}
                  loading={isAgentSessionsLoading || isTabLoading || isPaginationLoading}
                  rows={sessionData.sessions?.length === 0 ? [] : (searchTerm ? filteredAgentSessions : sessionData.sessions)}
                  columns={[
                    ...columns,
                    {
                      type: "custom",
                      fieldName: "actions",
                      headerName: "",
                      renderCell: ({ session, steps = [] }) => {
                        const { status, type, id } = session;
                        const isRowLoading = loadingRows[id];

                        const completedSteps = steps.filter(
                          ({ status, type }) =>
                            status === AgentSessionStatus.COMPLETE ||
                            (type === AgentSessionStepType.MULTI_VENDOR_PROFILE &&
                              status === AgentSessionStatus.INPUT_NEEDED) ||
                            status === AgentSessionStatus.IN_PROGRESS,
                        ).length;

                        const isReadyForDownload = ([AgentSessionStatus.COMPLETE, AgentSessionStatus.INPUT_NEEDED, AgentSessionStatus.IN_PROGRESS] as string[])
                          .includes(steps.find(step => step.type === AgentSessionStepType.EDIT_RESPONSE)?.status ?? "");

                        return (
                          <div className="flex justify-end w-full items-center h-full min-h-[40px]">
                            {isRowLoading ? (
                              <div className="w-8 h-8 flex items-center justify-center">
                                <LoadingSpinner
                                  variant="centered"
                                  className="h-5 w-5"
                                />
                              </div>
                            ) : (
                              <Dropdown>
                                <DropdownTrigger>
                                  <Button
                                    isIconOnly
                                    className="bg-transparent hover:bg-gray-100 transition-colors"
                                    isDisabled={isAgentSessionsLoading || !!isAgentSessionsResuming || !completedSteps}
                                  >
                                    <Ellipsis className="h-5 w-5" color="#717179" />
                                  </Button>
                                </DropdownTrigger>
                                <DropdownMenu
                                  disabledKeys={!isReadyForDownload ? ["download"] : []}
                                >
                                  <DropdownItem
                                    key="continue"
                                    onClick={() => handleSessionContinuation(session, steps)}
                                    startContent={
                                      <ArrowRight className="h-4 w-4 text-[#444444]" />
                                    }
                                    className="text-[#444444]"
                                  >
                                    {completedSteps === 5 ? "View Session" : "Continue Session"}
                                  </DropdownItem>
                                  <DropdownItem
                                    key="download"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDownloadReport(session, steps)
                                    }}
                                    startContent={
                                      <Download className="h-4 w-4 text-[#444444]" />
                                    }
                                    className="text-[#444444] "
                                  >
                                    Download Report
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            )}
                          </div>
                        );
                      },
                    },
                  ]}
                  isHeaderSticky={true}
                  isCompact={true}
                  removeWrapper={true}
                  getRowId={(row) => row.session.id}
                  tableHeaderProps={{
                    className: "shadow-none rounded-none ",

                  }}

                  tableBodyProps={{
                    emptyContent: (isAgentSessionsLoading || isTabLoading || isPaginationLoading) ? (
                      <div className="w-full py-4">
                        <Spinner />
                      </div>
                      // <div className="w-full py-4">
                      //   {[...Array(10)].map((_, index) => (
                      //     <div key={index} className="flex gap-4 w-full px-4 py-2">
                      //       <Skeleton className="w-[100%] h-12 rounded-lg" />
                      //     </div>
                      //   ))}
                      // </div>
                    ) : "No agent sessions found"
                  }}
                  onRowClick={handleSessionRowClick}
                  tableRowProps={(row, rowIndex) => ({
                    className: cn(
                      rowIndex === 0 ? "border-none" : "border-y",
                      "hover:bg-[#F2F2F2]",
                      "group",
                      "cursor-pointer",
                      loadingRows[row.session.id] && "cursor-wait",
                    ),
                  })}
                  classNames={{
                    wrapper: "!shadow-none !rounded-none",
                    base: "!shadow-none !rounded-none",
                    th: "!shadow-none !rounded-none  !bg-gradient-to-b !from-[#f5f2f4] !to-[rgb(245,242,244)]",
                    tr: "",
                  }}
                  aria-label="Agent Sessions History"
                />
                <TablePagination
                  currentPage={currentPage}
                  totalPages={sessionData.totalPages}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  isDisabled={isPaginationLoading}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grow overflow-hidden rounded-xl">
          <Outlet />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default AgentSessionsHistory;