/* eslint-disable no-constant-condition */
/* eslint-disable prettier/prettier */
import ErrorBoundary from '@/shared/components/error-boundary';
import { ZTable } from '@/shared/components/table/index';
import { cn } from '@/shared/utils/classname-merger';
import {
  Checkbox
} from '@nextui-org/react';
import { Loader } from 'lucide-react';
import { v4 as uuid } from 'uuid';
import {
  type Control,
  isControlValid,
  SecurityControlCategory,
  TSCABBRIVATIONS,
  TSCENUM,
  useCustomizeControlsStore
} from '../../states/cusomize-controls';

import { ZButton } from '@/shared/components/button';
import { ZTableRowParams } from '@/shared/components/table';
import Fuse from 'fuse.js';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { handleAutoSaveCustomizeControls } from '../../use-cases/customize-control.use-case';
import { CustomizeControlCategoryRenderer, CustomizeControlDescriptionRenderer, CustomizeControlIdRenderer, CustomizeControlTestingProcedureRenderer } from './column-cell-renderers';
import { getRandomCharacter, ZInputWithOnChangeDelay } from './helpers';


const CustomizeControls = () => {

  const { id = '' } = useParams<{ id: string }>();

  const [searchQuery, setSearchQuery] = useState('');

  const { dataState, accordianOpenState, selectedControls,isSavingControls } =
    useCustomizeControlsStore();
  const { setDataState, setAccordianOpenState, setSelectedControls,setIsSavingControls, reset } =
    useCustomizeControlsStore().actions;

  

  const fuse = useMemo(() => {
    return new Fuse(dataState, {
      keys: [
        'category',
        'toe',
        'control',
        'control_id',
        'tsc',
        'tsc_id',
        'zania_control_id',
      ],
      threshold: 0.3,
      findAllMatches: true,
      ignoreLocation: true,
    });
  }, [dataState]);

  const filteredRows = useMemo(() => {
    return searchQuery ? fuse
      .search(searchQuery)
      .map((result) => result.item)
      .filter(Boolean) : dataState;
  }, [dataState, fuse, searchQuery]);

  const sectionWiseData = useMemo(() => {
    const sectionData = filteredRows.reduce(
      (acc, next) => {
        if (next.tsc in acc) {
          acc[next.tsc].push(next);
        } else {
          acc[next.tsc] = [next];
        }
        return acc;
      },
      {
        security: [],
        availability: [],
        confidentiality: [],
        'processing integrity': [],
        privacy: [],
      } as { [key in TSCENUM]: Control[] }
    );
    const output = [] as Control[];

    for (const key of Object.keys(sectionData)) {
      
      output.push({
        category: '',
        toe: '',
        tod: '',
        control: '',
        control_id: '',
        criteria: '',
        tsc: key as TSCENUM,
        tsc_id: key,
        uid: key,
        zania_control_id: '',
        edited_state: 'ACCORDIAN',
      });
      if (accordianOpenState[key as TSCENUM]) {
        output.push(...sectionData[key as TSCENUM]);
        const uid = getRandomCharacter() + uuid().replaceAll("-", "")
        if(selectedControls.size === 0 && searchQuery.length === 0){
          output.push({
            category: '',
            toe: '',
            tod: '',
            control: '',
            control_id: '',
            criteria: '',
            tsc: key as TSCENUM,
            tsc_id: '',
            uid,
            zania_control_id: '',
            edited_state: 'NEW',
          });
        }
      }
    }

    return output.map(d => ({...d, id:d.uid}));
  }, [accordianOpenState, filteredRows, selectedControls.size, searchQuery.length]);


  const autosaveTimerRef = useRef<NodeJS.Timeout | null>(null)

  const handleAutoSave = useCallback(() => {
    setIsSavingControls('EDITED')
    if (autosaveTimerRef.current) {
      clearTimeout(autosaveTimerRef.current)
    }
    autosaveTimerRef.current = setTimeout(() => {
      setIsSavingControls('SAVING')
      handleAutoSaveCustomizeControls(id).then(() => {
        setIsSavingControls('SAVED')

      }).catch(err => console.error(err))
    }, 500)
  },[id, setIsSavingControls])
  
  const handleChangeDataState = (
    key: keyof Control,
    uId: string,
    newValue: string
  ) => {
    setDataState((prev) => {
      const newDataState = [...prev];
      const foundIndexOfElement = newDataState.findIndex((d) => d.uid === uId);
      if (foundIndexOfElement > -1) {
        if (key === 'tsc_id') {
          let modifiedNewValue = newValue

          if (modifiedNewValue.trim().length > 0 && Object.values(TSCABBRIVATIONS).reduce((acc, abbrivation) => modifiedNewValue.startsWith(abbrivation) || acc, false) === false) {
            modifiedNewValue = `${TSCABBRIVATIONS[newDataState[foundIndexOfElement].tsc]}${modifiedNewValue}`
          }

          newDataState[foundIndexOfElement].control_id = modifiedNewValue;
          if (newDataState[foundIndexOfElement].edited_state !== 'default') {
            newDataState[foundIndexOfElement].tsc_id = modifiedNewValue;
          }
        }
        if (key === 'control') {
          newDataState[foundIndexOfElement].control = newValue;
        }
        if (key === 'category') {
          newDataState[foundIndexOfElement].category = newValue as SecurityControlCategory;
        }
        if (key === 'toe') {
          newDataState[foundIndexOfElement].toe = newValue;
          newDataState[foundIndexOfElement].tod = newValue;
        }

        if(key === 'new_control'){
          newDataState[foundIndexOfElement].new_control = newValue;
        }
        if(key === 'new_tod' || key === 'new_toe'){
          newDataState[foundIndexOfElement].new_tod = newValue;
          newDataState[foundIndexOfElement].new_toe = newValue;
        }
        

        if (newDataState[foundIndexOfElement].toe.trim().length === 0  && newDataState[foundIndexOfElement].tsc_id === TSCABBRIVATIONS[newDataState[foundIndexOfElement].tsc] && newDataState[foundIndexOfElement].category.trim().length === 0) {
          const removedUid = newDataState[foundIndexOfElement].uid
          setSelectedControls((prev) => {
            const newSelectedControls = new Set(prev)
            newSelectedControls.delete(removedUid)
            return newSelectedControls
          })
          
          newDataState.splice(foundIndexOfElement, 1)
        }else {
          if( !isControlValid(newDataState[foundIndexOfElement])){
            // setSelectedControls((prev) => {
            //   const newSelectedControls = new Set(prev)
            //   newSelectedControls.delete(newDataState[foundIndexOfElement].uid)
            //   return newSelectedControls
            // })
            // newDataState[foundIndexOfElement].selected = false
          }else if (isControlValid(newDataState[foundIndexOfElement]) && newDataState[foundIndexOfElement].edited_state === 'custom'){
            // setSelectedControls((prev) => {
            //   const newSelectedControls = new Set(prev)
            //   newSelectedControls.add(newDataState[foundIndexOfElement].uid)
            //   return newSelectedControls
            // })
            // newDataState[foundIndexOfElement].selected = true
          }
        }


        

      } else {
        const newObject = Object.values(sectionWiseData).find(
          (d) => d.uid === uId
        );
        if (newObject) {
          if (key === 'tsc_id') {

            let modifiedNewValue = newValue

            if (Object.values(TSCABBRIVATIONS).reduce((acc, abbrivation) => modifiedNewValue.startsWith(abbrivation) || acc, false) === false) {
              modifiedNewValue = `${TSCABBRIVATIONS[newObject.tsc]}${modifiedNewValue}`
            }

            newObject.control_id = modifiedNewValue;
            if (newObject.edited_state !== 'default') {
              newObject.tsc_id = modifiedNewValue;
            }
          }
          if (key === 'control') {
            newObject.control = newValue;
          }
          if (key === 'category') {
            newObject.category = newValue as SecurityControlCategory;
          }
          if (key === 'toe') {
            newObject.toe = newValue;
          }

          newDataState.push({
            ...newObject,
            edited_state: 'custom',
          });
          setTimeout(() => {
            const d = document.getElementById(uId + key);
            d?.focus();
          }, 0);
        }
      }

      handleAutoSave()
      return newDataState;
    });
  };

  const getDuplicateErrorMessage = (control: Control): string | undefined => {

    const temp = dataState.filter((c) => c.uid !== control.uid)

    if (temp.find(c => c.tsc_id.trim() === control.tsc_id.trim())) {
      return "Duplicate Control ID"
    }

    return undefined
  }

  const validControl = useMemo(() => {
    return dataState.filter(c => isControlValid(c))
  }, [dataState])


  const onRowClickHandler = ({ row }: ZTableRowParams<Control>) => {
    if (row.edited_state === 'ACCORDIAN') {
      setAccordianOpenState(row.tsc, !accordianOpenState[row.tsc])
    }
  }

  const handleOnClickDeleteButtom = useCallback(() => {
    setDataState((prev) => {
      let newValue = [...prev]

      newValue = newValue.filter(v => selectedControls.has(v.uid) === false)
      
      setSelectedControls(new Set<string>())
      handleAutoSave()
      
      return newValue
    })
  },[setDataState, selectedControls, setSelectedControls, handleAutoSave])
  
  return (
    <ErrorBoundary fallback={null}>
      <div className="h-full w-full overflow-hidden rounded-lg flex flex-col">
        <div className="px-6 py-4 grid grid-cols-3 min-h-[69px] bg-white  gap-2 border-b border-border">
          <div className="col-span-1 items-center my-auto">
            <div>
              {selectedControls.size >0 ? (
                <>
                <div className='text-sm font-medium'>{selectedControls.size} control{selectedControls.size>1 ? "s":""} selected</div>
                </>
              ) : (
                <div className='flex gap-2'>
                  <div className='text-sm font-medium'>Edit or Add Controls</div>
                  <div className="p-1 bg-[#F4F4F5] px-2 rounded-lg">
                    <p className="text-[10px] text-[#52525B] leading-4   font-medium">
                      {`${validControl.length}`}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-span-1'>
            {selectedControls.size === 0 && (
              <ZInputWithOnChangeDelay
                classNames={{
                  inputWrapper: 'rounded-md h-9 min-h-9',
                  mainWrapper: 'max-h-9 bg',
                }}
                className="grow max-w-96 min-w-[480px]  h-9"
                placeholder="Enter keywords to search"
                value={searchQuery}
                onChange={(e) => { setSearchQuery(e.target.value) }}
              />
            )}
          </div>
          <div className='col-span-1 flex justify-end items-center my-auto'>
            <div>
              {isSavingControls === 'SAVING' && (
                <span className="flex items-center min-w-fit gap-1">
                  <Loader className="w-4 h-4 animate-spin" />
                  <p className="text-tiny text-[#A5A5A5] text-[8px]">Saving</p>
                </span>
              )}
              {selectedControls.size >0 && (
                <ZButton color='danger' variant='bordered' className='text-sm font-medium max-h-7 max-w-32' onClick={handleOnClickDeleteButtom} onMouseDown={handleOnClickDeleteButtom}>
                  Delete Control{selectedControls.size>1 ? "s":""}
                </ZButton>
              )}
            </div>
          </div>
        </div>
        <div className="grow overflow-hidden flex ">
          <div id="customize-control-table" className="flex-1 flex flex-col bg-white overflow-auto">
            <ZTable<Control>
              aria-label="Customize Controls list"
              isCompact={true}
              removeWrapper={true}
              columns={[
                {
                  fieldName: 'id',
                  headerName: 'id',
                  renderCell: (control: Control) => <CustomizeControlIdRenderer accordianOpenState={accordianOpenState} control={control} getDuplicateErrorMessage={getDuplicateErrorMessage} handleAutoSave={handleAutoSave} handleChangeDataState={handleChangeDataState} searchQuery={searchQuery} selectedControls={selectedControls} setAccordianOpenState={setAccordianOpenState} setSelectedControls={setSelectedControls}  />,
                  renderHeader: () => {
                    return (
                      <div className="flex w-[216px]">
                        <Checkbox
                          isSelected={dataState.length === selectedControls.size && selectedControls.size > 0}
                          isIndeterminate={
                            selectedControls.size > 0 && dataState.length !== selectedControls.size
                          }
                          classNames={{
                            base: cn('group'),
                            wrapper: cn(
                              'group-data-[disabled=true]:border-default',
                              'after:group-data-[disabled=true]:bg-default-100'
                            ),
                            icon: 'group-data-[disabled=true]:text-default-300',
                          }}
                          onChange={() => {
                            if (dataState.length === selectedControls.size) {
                              setSelectedControls(new Set([]))
                            } else {
                              setSelectedControls(new Set(dataState.map(d => d.uid)))
                            }
                          }}
                          data-component="z-table-row-select"
                          color={'primary'}
                          disabled={searchQuery.length > 0}
                        />
                        <div className="text-[12px] leading-5 font-semibold">
                          ID
                        </div>
                      </div>
                    );
                  },
                },
                {
                  fieldName: 'category',
                  headerName: 'Category',
                  renderCell: (control) => <CustomizeControlCategoryRenderer control={control} handleChangeDataState={handleChangeDataState} searchQuery={searchQuery} />
                },
                {
                  fieldName: 'control',
                  headerName: 'Control Description',
                  minWidth: 652,
                  renderCell: (control: Control) => <CustomizeControlDescriptionRenderer  control={control} handleChangeDataState={handleChangeDataState} searchQuery={searchQuery} />,
                },
                {
                  fieldName: 'testing_procedure',
                  headerName: 'Testing Procedure',
                  minWidth: 372,
                  renderCell: (control: Control) => <CustomizeControlTestingProcedureRenderer  control={control} handleChangeDataState={handleChangeDataState} searchQuery={searchQuery}/>,
                },
              ]}
              tableRowProps={(control) => {
                return {
                  className: cn(
                    'border-t-1 border-b-1 border-[#E4E4E7] bg-white pb-2',
                    'min-h-[36px]',
                    control.edited_state === 'ACCORDIAN' && "!bg-zinc-100",
                     control.edited_state === 'NEW' && "!bg-zinc-50"
                  ),
                };
              }}
              tableColumnProps={({
                fieldName
              }) => ({
                className: cn(fieldName === "control" &&"pl-[16px]", fieldName === '' && "w-[216px]", fieldName === 'category' && "w-[240px]", fieldName === 'control' && "min-w-[420px]", fieldName === 'testing_procedure' && "min-w-[372px]")
              })}
              classNames={{
                tr: 'hover:bg-[#ECECEC] !rounded-none ',
                th: "bg-[#F9F7F7] !rounded-none"
                // td: 'h-full ',
              }}
              tableHeaderProps={{
                className: "!rounded-none",
                
              }}
              // className='transition'
              onRowClick={onRowClickHandler}
              tableCellProps={() => ({ className: cn('align-baseline ') })}
              rows={sectionWiseData}
              isHeaderSticky={true}
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default CustomizeControls;