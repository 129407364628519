import clsx from "clsx";
import { ReviewSourceTypes } from "../../types/index.ts";
import AgentEditFieldListWithInlineSources from "./AgentEditFieldListWithInlineSources";
import AgentEditFieldWithInlineSources, {
  FieldValueWithInlineSources,
} from "./AgentEditFieldWithInlineSources";
import { convertSnakeToTitleCase } from "../../utils/snake-to-capital.ts";

export interface Props {
  fields: {
    title: string;
    value: any;
    onChange: (value: FieldValueWithInlineSources[]) => void;
    isNumber?: boolean;
  }[];
  showHorizontal?: boolean;
  onSourceClick: (selectedSource: ReviewSourceTypes) => void;
  expandedView?: boolean;
  isDisabled?: boolean;
}

const CombinedAgentEditFieldWithInlineSources = ({
  fields,
  showHorizontal,
  onSourceClick,
  expandedView,
  isDisabled,
}: Props) => {
  return (
    <div
      className={`border-1 border-[#E4E4E7] rounded-md overflow-hidden flex items-stretch bg-white w-full ${showHorizontal ? "flex-row" : "flex-col"}`}
    >
      {fields.map((field, idx) => (
        <div
          className={clsx(
            "w-full h-auto",
            showHorizontal && idx > 0 && idx < fields.length && "border-l-1",
          )}
          key={idx}
        >
          {Array.isArray(field.value) ? (
            <AgentEditFieldListWithInlineSources
              fieldValueObjArray={field.value}
              placeholder={`Add ${convertSnakeToTitleCase(field.title)}`}
              onChange={field.onChange}
              onSourceClick={onSourceClick}
              title={field.title}
              showTitle={true}
              noBorder={true}
              expandedView={expandedView}
              isDisabled={isDisabled}
            />
          ) : (
            <AgentEditFieldWithInlineSources
              fieldValueObj={field.value}
              onChange={(val) => field.onChange([val])}
              placeholder={`Add ${convertSnakeToTitleCase(field.title)}`}
              onSourceClick={onSourceClick}
              title={field.title}
              noBorder={true}
              expandedView={expandedView}
              minRows={2}
              maxRows={999999}
              isDisabled={isDisabled}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default CombinedAgentEditFieldWithInlineSources;
