import { useAgentStepData } from "@/modules/agent/states";
import { AgentSessionStatus } from "@/modules/sessions/types";
import { AgentSessionStepType } from "@/modules/sessions/types";
import { useParams } from "react-router-dom";
/* eslint-disable prettier/prettier */
import { useVendorRiskAssessment } from "../useVendorRiskAssessment/useVendorRiskAssessment";
import { useVendorRiskAssessmentStore } from "../useVendorRiskAssessment/useVendorRiskAssessmentStore";

import {
  VENDOR_DATA_TYPES_HANDLED,
  VENDOR_INCIDENTS_IN_LAST_36_MONTHS,
} from "@/modules/agent/constants/vendorAssessmentConstants";
import {
  VendorOverviewKey,
  VendorOverviewKeyToValueTypeMap,
  VendorRiskResponseItemForVendorOverview,
} from "@/modules/agent/types/vendorOverviewSection";
import {
  ComplianceAdherenceCard,
  DropDownAndTextAreaCard,
  RiskAndJustitificationCard,
  TextAreaCard,
} from "./OverviewCards";

export interface IdParams {
  id: string;
}

const Overview = () => {
  const { vendorOverview } = useVendorRiskAssessment();
  const setVendorOverview = useVendorRiskAssessmentStore().setVendorOverview;

  const { id } = useParams<keyof IdParams>() as IdParams;
  const stepData = useAgentStepData(id);
  const isReportReady =
    stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT)
      ?.status === AgentSessionStatus.COMPLETE;

  const getFieldValue = <
    K extends VendorOverviewKey,
    V extends keyof VendorOverviewKeyToValueTypeMap[K]
  >(
    fieldKey: K,
    valueKey: V
  ) => {
    const item = vendorOverview.find(
      (overviewItem) => overviewItem.key === fieldKey
    );
    return (item?.value as VendorOverviewKeyToValueTypeMap[K])[valueKey];
  };

  const handleFieldValueChange = <
    K extends VendorOverviewKey,
    V extends keyof VendorOverviewKeyToValueTypeMap[K]
  >(
    fieldKey: K,
    valueKey: V,
    newValue: any
  ) => {
    const newVendorOverview = [...vendorOverview];
    const indexOfOverviewItem = vendorOverview.findIndex(
      (overviewItem) => overviewItem.key === fieldKey
    );
    if (indexOfOverviewItem > -1) {
      (newVendorOverview[indexOfOverviewItem]
        .value as VendorOverviewKeyToValueTypeMap[K])[valueKey] = newValue;
    }
    setVendorOverview(newVendorOverview);
  };

  return (
    <div className="p-6 grid gap-4 grid-cols-2 w-full bg-white">
      <div className="col-span-2">
        <RiskAndJustitificationCard
          title="Risk & Justification"
          riskLevel={getFieldValue("risk_rating", "value")}
          updateRiskLevel={(newValue) =>
            handleFieldValueChange("risk_rating", "value", newValue)
          }
          handleTextAreaValueChange={(newValue) =>
            handleFieldValueChange("risk_rating", "justification", newValue)
          }
          textAreaValue={getFieldValue("risk_rating", "justification") ?? ""}
          isDisabled={isReportReady}
        />
      </div>
      <TextAreaCard
        title="Company Information"
        handleTextAreaValueChange={(newValue) =>
          handleFieldValueChange("company_description", "value", newValue)
        }
        textAreaValue={getFieldValue("company_description", "value") ?? ""}
        isDisabled={isReportReady}
      />
      <TextAreaCard
        title="Product/ Service Information"
        handleTextAreaValueChange={(newValue) =>
          handleFieldValueChange("description_of_services", "value", newValue)
        }
        textAreaValue={getFieldValue("description_of_services", "value") ?? ""}
        isDisabled={isReportReady}
      />
      <TextAreaCard
        title="Integration Requirements"
        handleTextAreaValueChange={(newValue) =>
          handleFieldValueChange("integration_overview", "value", newValue)
        }
        textAreaValue={getFieldValue("integration_overview", "value") ?? ""}
        isDisabled={isReportReady}
      />
      <ComplianceAdherenceCard
        title="Compliance Adherence"
        values={getFieldValue("compliance", "value") ?? []}
        listOfExtraOptions={
          (vendorOverview.find(
            (overviewItem) => overviewItem.key === "compliance"
          ) as VendorRiskResponseItemForVendorOverview<"compliance">)
            ?.customer_added_certifications ?? []
        }
        handleChange={(newValues) =>
          handleFieldValueChange("compliance", "value", newValues)
        }
        isDisabled={isReportReady}
      />
      <DropDownAndTextAreaCard
        title="Data Types Handled"
        selectionMode="multiple"
        dropDownOptions={[...VENDOR_DATA_TYPES_HANDLED]}
        handleDropDownChanges={(newValues) =>
          handleFieldValueChange("data_types_handled", "data_types", newValues)
        }
        dropdownValues={getFieldValue("data_types_handled", "data_types") ?? []}
        textAreaValue={getFieldValue("data_types_handled", "purpose") ?? ""}
        handleTextAreaValueChange={(newValue) =>
          handleFieldValueChange("data_types_handled", "purpose", newValue)
        }
        otherValues={getFieldValue("data_types_handled", "other_data_types") ?? ""}
        handleOtherValueChange={(newValue) =>
          handleFieldValueChange("data_types_handled", "other_data_types", newValue)
        }
        isDisabled={isReportReady}
      />
      <DropDownAndTextAreaCard
        title="Incidents in Last 36 Months"
        selectionMode="single"
        dropDownOptions={[...VENDOR_INCIDENTS_IN_LAST_36_MONTHS]}
        handleDropDownChanges={(newValues) =>
          handleFieldValueChange(
            "security_incidents",
            "incident_history",
            newValues[0]
          )
        }
        dropdownValues={[
          getFieldValue("security_incidents", "incident_history"),
        ]}
        textAreaValue={
          getFieldValue("security_incidents", "incident_details") ?? ""
        }
        handleTextAreaValueChange={(newValue) =>
          handleFieldValueChange(
            "security_incidents",
            "incident_details",
            newValue
          )
        }
        isDisabled={isReportReady}
      />
    </div>
  );
};

export default Overview;
