import ZAvatarGradient from "@/shared/components/avatarGradient";
import { ZButton } from "@/shared/components/button";
import {
  ZModal,
  ZModalBody,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
} from "@/shared/components/modal";
import { ROUTES } from "@/shared/constants/routes";
import { addNotification } from "@/shared/states/notification";
import { useStytch } from "@/shared/states/stytch";
import { nestedDeepEqual } from "@/shared/utils/object-equality";
import {
  Checkbox,
  Chip,
  Select,
  SelectItem,
  SelectSection,
} from "@nextui-org/react";
import { useStytchMember, useStytchOrganization } from "@stytch/react/b2b";
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getZaniaRoleLabelMap, useTeamState } from "../../states";
import { getMember } from "../../states";
import { OrganizationTrustedMetaData, TeamRole } from "../../types";
import { updateTeamMemberUseCase } from "../../use-cases/team.use-case";
import {
  listOfAccess,
  mapIsCurrentMemberOrgAdmin,
  printErrorMessage,
} from "../../utils";
const MemberUpdateDialog = () => {
  const { team } = useTeamState();
  const stytch = useStytch();
  const stytchMember = useStytchMember();
  const organization = useStytchOrganization();
  const organizationTrustedMetaData: OrganizationTrustedMetaData | undefined =
    organization.organization?.trusted_metadata as
      | OrganizationTrustedMetaData
      | undefined;

  const isCurrentMemberOrgAdmin = useMemo(
    () => mapIsCurrentMemberOrgAdmin(team, stytchMember?.member?.member_id),
    [team, stytchMember?.member?.member_id],
  );
  const [loading, setLoading] = useState(false);
  const { memberId } = useParams();

  const member = getMember(memberId as string);

  const navigate = useNavigate();

  const [memberDetails, setMemberDetails] = useState({
    role: (member?.roles?.find?.(
      ({ role_id }) =>
        [TeamRole.ORG_ADMIN, TeamRole.ADMIN, TeamRole.MEMBER].indexOf(
          role_id as TeamRole,
        ) > -1,
    )?.role_id ?? "") as TeamRole,
    access:
      member?.roles
        ?.filter?.(
          ({ role_id }) =>
            [TeamRole.ORG_ADMIN, TeamRole.ADMIN, TeamRole.MEMBER].indexOf(
              role_id as TeamRole,
            ) === -1,
        )
        ?.map(({ role_id }) => role_id as TeamRole) ?? [],
  });

  const onCloseDialog = useCallback(() => {
    navigate(`/${ROUTES.TEAM}`, { replace: true });
  }, [navigate]);

  useEffect(() => {
    if (!memberId || !member) {
      onCloseDialog();
    }
  }, [memberId, member, onCloseDialog]);

  const handleOnClickUpdate = async () => {
    const { role, access } = memberDetails;
    if (typeof role !== "string" || role.length === 0) {
      addNotification({
        type: "error",
        title: "Invalid member role",
        message: "Please select role",
      });
      return;
    } else if (!Array.isArray(access) || access.length === 0) {
      addNotification({
        type: "error",
        title: "Invalid member access",
        message: "Please select access",
      });
      return;
    }
    setLoading(true);
    try {
      await updateTeamMemberUseCase({
        stytch,
        updates: {
          memberId: memberId as string,
          role,
          access: access.filter((item) => {
            return item !== TeamRole.MEMBER;
          }),
        },
      });
      addNotification({
        type: "success",
        title: "Profile Settings Updated",
        message: "Your team member's profile has been updated successfully.",
      });
      onCloseDialog();
    } catch (error) {
      console.error(error);
      addNotification({
        type: "error",
        title:
          ((error as Error).cause as string) ??
          `Could not update ${[member?.name].filter(Boolean).join(" ") ?? "team members"} profile`,
        message: printErrorMessage(JSON.stringify(error)),
      });
    } finally {
      setLoading(false);
    }
  };
  const currentMemberRoles = useMemo(() => {
    const returnArr: Array<string> = [TeamRole.MEMBER];
    if (isCurrentMemberOrgAdmin) {
      if (stytchMember.member?.member_id !== memberId) {
        returnArr.push(TeamRole.ADMIN, TeamRole.ORG_ADMIN);
      } else {
        returnArr.push(TeamRole.ADMIN);
      }
    }
    return returnArr;
  }, [isCurrentMemberOrgAdmin, stytchMember.member?.member_id]);

  const isThereChangesMade = useMemo(() => {
    const initialMemberDetails = {
      role: (member?.roles?.find?.(
        ({ role_id }) =>
          [TeamRole.ORG_ADMIN, TeamRole.ADMIN, TeamRole.MEMBER].indexOf(
            role_id as TeamRole,
          ) > -1,
      )?.role_id ?? "") as TeamRole,
      access:
        member?.roles
          ?.filter?.(
            ({ role_id }) =>
              [TeamRole.ORG_ADMIN, TeamRole.ADMIN, TeamRole.MEMBER].indexOf(
                role_id as TeamRole,
              ) === -1,
          )
          ?.map(({ role_id }) => role_id as TeamRole) ?? [],
    };

    return !nestedDeepEqual(memberDetails, initialMemberDetails);
  }, [member?.roles, memberDetails]);

  return (
    <ZModal defaultOpen onOpenChange={(isOpen) => !isOpen && onCloseDialog()}>
      <ZModalContent>
        <ZModalHeader className="w-full flex flex-col gap-2">
          <h4 className="text-center">Update Profile Settings</h4>
          <p className="text-center font-normal text-sm text-[#777780]">
            Edit what your team member can access and control
          </p>
        </ZModalHeader>
        <ZModalBody className="w-full px-6 py-6 gap-6">
          <div className="flex items-center gap-3 bg-[#F7F7FA] px-6 py-4 rounded-lg">
            <ZAvatarGradient
              size={42}
              textSize={18}
              name={member?.name ?? ""}
            />
            <div className="flex flex-col items-start">
              <p className="font-bold">{member?.name}</p>
              <p className="font-medium text-[#777780] text-tiny">
                {member?.email_address}
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col gap-4">
            <Select
              id="role"
              name="role"
              label="Role"
              labelPlacement="outside"
              placeholder="Select role"
              items={getZaniaRoleLabelMap().filter(
                ({ roleId }) => currentMemberRoles.indexOf(roleId) > -1,
              )}
              className="w-full border-1 border-gray-300 rounded-md"
              classNames={{
                trigger: "w-full shadow-none border-none rounded-md py-2",
              }}
              selectedKeys={[memberDetails.role]}
              onSelectionChange={(role) => {
                setMemberDetails((prev) => ({
                  ...prev,
                  role: Array.from(role as Set<TeamRole>)[0],
                }));
              }}
              disabled={loading}
            >
              {(data) => (
                <SelectItem
                  key={data.roleId}
                  textValue={data.label}
                  value={data.roleId}
                  description={
                    typeof data.description === "string" &&
                    data.description.length > 0
                      ? data.description
                      : undefined
                  }
                >
                  {data.label}
                </SelectItem>
              )}
            </Select>
            <div className="flex flex-col gap-y-2">
              <label
                className="pointer-events-none text-small max-w-full text-ellipsis overflow-hidden"
                id="access-label"
                htmlFor="access"
              >
                Access
              </label>
              <Select
                id="access"
                name="access"
                label={null}
                aria-label="Access"
                aria-labelledby="access-label"
                placeholder="Select access"
                selectionMode="multiple"
                multiple={true}
                isMultiline={true}
                items={getZaniaRoleLabelMap().filter(({ roleId }) =>
                  listOfAccess.includes(roleId),
                )}
                className="w-full border-1 border-gray-300 rounded-md"
                classNames={{
                  trigger: "w-full shadow-none border-none rounded-md py-2",
                }}
                renderValue={(items) => {
                  return (
                    <div className="flex flex-wrap gap-2">
                      {items.map(({ data, textValue, key }) => (
                        <Chip
                          key={data?.roleId}
                          variant="bordered"
                          radius="sm"
                          isCloseable={true}
                          onClose={() => {
                            setMemberDetails((prev) => ({
                              ...prev,
                              access: prev.access.filter(
                                (roleId) => roleId !== key,
                              ),
                            }));
                          }}
                          className="bg-white border-small"
                          isDisabled={loading}
                        >
                          {textValue}
                        </Chip>
                      ))}
                    </div>
                  );
                }}
                selectedKeys={["lite", ...memberDetails.access]}
                onSelectionChange={(value) => {
                  setMemberDetails((prev) => ({
                    ...prev,
                    access: Array.from(value as Set<TeamRole>).filter(
                      (role) => role !== ("lite" as TeamRole),
                    ),
                  }));
                }}
                disabled={loading}
              >
                <SelectSection
                  title="AI Agent"
                  classNames={
                    {
                      // heading: headingClasses,
                    }
                  }
                >
                  {getZaniaRoleLabelMap()
                    .filter(({ roleId }) =>
                      [
                        ...Object.values(
                          organizationTrustedMetaData?.feature_roles ?? {},
                        ),
                      ].includes(
                        roleId as
                          | TeamRole.ZANIA_GAP_ASSESSMENT
                          | TeamRole.ZANIA_DORA_ASSESSMENT
                          | TeamRole.ZANIA_SOC2_ASSESSMENT
                          | TeamRole.ZANIA_RISK_ASSESSMENT
                          | TeamRole.ZANIA_VENDOR_ASSESSMENT,
                      ),
                    )
                    .map((data) => (
                      <SelectItem
                        key={data.roleId}
                        textValue={data.label}
                        value={data.roleId}
                        hideSelectedIcon
                        className="gap-4 px-2 py-2"
                        classNames={{
                          title: "overflow-auto whitespace-normal",
                        }}
                      >
                        <div className="h-full w-full flex gap-2 items-start overflow-hidden">
                          <Checkbox
                            isSelected={memberDetails.access.includes(
                              data.roleId,
                            )}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            onMouseDown={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            onChange={({ target: { checked } }) => {
                              setMemberDetails((prev) => ({
                                ...prev,
                                access: checked
                                  ? prev.access.includes(data.roleId)
                                    ? prev.access
                                    : prev.access.concat(data.roleId)
                                  : prev.access.filter(
                                      (roleId) => roleId !== data.roleId,
                                    ),
                              }));
                            }}
                            disabled={loading}
                          />
                          <div className="h-full w-full">
                            <span className="text-small">{data.label}</span>
                          </div>
                        </div>
                      </SelectItem>
                    ))}
                </SelectSection>
                <SelectSection
                  title="Response Quality"
                  classNames={
                    {
                      // heading: headingClasses,
                    }
                  }
                >
                  {getZaniaRoleLabelMap()
                    .filter(({ roleId }) =>
                      [
                        ...Object.values(
                          organizationTrustedMetaData?.response_quality_roles ??
                            {},
                        ),
                        "lite",
                      ].includes(roleId),
                    )
                    .map((data) => (
                      <SelectItem
                        key={data.roleId}
                        textValue={data.label}
                        value={data.roleId}
                        hideSelectedIcon
                        className="gap-4 px-2 py-2"
                        classNames={{
                          title: "overflow-auto whitespace-normal",
                        }}
                      >
                        <div className="h-full w-full flex gap-2 items-start overflow-hidden">
                          <Checkbox
                            isSelected={
                              memberDetails.access.includes(data.roleId) ||
                              data.roleId === ("lite" as TeamRole)
                            }
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            onMouseDown={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            onChange={({ target: { checked } }) => {
                              if (data.roleId !== ("lite" as TeamRole)) {
                                setMemberDetails((prev) => ({
                                  ...prev,
                                  access: checked
                                    ? prev.access.includes(data.roleId)
                                      ? prev.access
                                      : prev.access.concat(data.roleId)
                                    : prev.access.filter(
                                        (roleId) => roleId !== data.roleId,
                                      ),
                                }));
                              }
                            }}
                            isDisabled={data.roleId === ("lite" as TeamRole)}
                            disabled={
                              loading || data.roleId === ("lite" as TeamRole)
                            }
                          />
                          <div className="h-full w-full ">
                            <span className="text-small">{data.label}</span>
                          </div>
                        </div>
                      </SelectItem>
                    ))}
                </SelectSection>
              </Select>
            </div>
          </div>
        </ZModalBody>
        <ZModalFooter className="w-full justify-stretch">
          <ZButton
            variant="bordered"
            onClick={onCloseDialog}
            disabled={loading}
            className="flex-auto"
          >
            Cancel
          </ZButton>
          <ZButton
            type="submit"
            isLoading={loading}
            onClick={() => void handleOnClickUpdate()}
            isDisabled={loading || !isThereChangesMade}
            className="flex-auto"
          >
            {loading ? "Please Wait" : "Update"}
          </ZButton>
        </ZModalFooter>
      </ZModalContent>
    </ZModal>
  );
};

export default MemberUpdateDialog;
