import { ZButton } from "@/shared/components/button";
import { ZInput } from "@/shared/components/input";
import {
  ZModal,
  ZModalBody,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
} from "@/shared/components/modal";
import React, { useState } from "react";

interface AddCertificateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (certificateName: string) => void;
}

const AddCertificateModal: React.FC<AddCertificateModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [certificateName, setCertificateName] = useState("");

  const handleSubmit = () => {
    onSubmit(certificateName);
    setCertificateName("");
    onClose();
  };

  const handleOnClose = () => {
    setCertificateName("");
    onClose();
  };

  return (
    <ZModal isOpen={isOpen} onOpenChange={handleOnClose}>
      <ZModalContent className="w-[458px] h-[256px] p-6">
        <ZModalHeader className="flex justify-center p-0 mb-6">
          <h2 className="text-[18px] font-semibold">Add Certificate</h2>
        </ZModalHeader>
        <ZModalBody className="p-0">
          <ZInput
            label="Certificate Name"
            labelPlacement="outside"
            placeholder="Add name"
            value={certificateName}
            onChange={(e) => setCertificateName(e.target.value.trimStart())}
            classNames={{
              label: "text-[14px] text-[#71717A] mb-2",
              inputWrapper:
                "w-full shadow-none  rounded-md border-1 border-gray-300",
            }}
          />
        </ZModalBody>
        <ZModalFooter className="flex justify-between p-0 mt-6">
          <ZButton
            variant="bordered"
            color="primary"
            onClick={handleOnClose}
            className="flex-1 mr-3"
          >
            Discard
          </ZButton>
          <ZButton
            color={certificateName.trim() ? "primary" : "default"}
            onClick={handleSubmit}
            className={`flex-1 ${
              !certificateName.trim() && "opacity-50 cursor-not-allowed"
            }`}
            disabled={!certificateName.trim()}
          >
            Submit
          </ZButton>
        </ZModalFooter>
      </ZModalContent>
    </ZModal>
  );
};

export default AddCertificateModal;
