import { Textarea } from "@nextui-org/react";
import React, { useState } from "react";
import { ReviewSourceTypes } from "../../types";
import InlineSources from "./InlineSources";
import clsx from "clsx";
import { TestResultsValue } from "../../types/risk-and-gap";
import { cn } from "@/shared/utils/classname-merger";

interface Props {
  values: TestResultsValue[];
  handleChanges: (newValues: TestResultsValue[]) => void;
  expandedView?: boolean;
}

const TestResults = ({ handleChanges, values, expandedView }: Props) => {
  const handleChange = (
    key: keyof TestResultsValue,
    value: ReviewSourceTypes[] | string,
    idx: number
  ) => {
    const newValue = [...values];

    if (key === "result") {
      newValue[idx].result = value as string;
    }
    if (key === "test") {
      newValue[idx].test = value as string;
    }
    if (key === "sources") {
      newValue[idx].sources = value as ReviewSourceTypes[];
    }

    handleChanges(newValue);
  };

  return (
    <div className="relative bg-white rounded-md border-1 border-[#E4E4E7]  w-full">
      <div className={cn("flex flex-col  w-full", expandedView && "flex-row ")}>
        {values.map((value, idx) => (
          <React.Fragment key={idx}>
            <div
              className={cn(
                "flex flex-col",
                idx < values.length && "border-b-1",
                expandedView && "flex-col grow",
                expandedView && idx > 0 && idx < values.length && "border-l-1"
              )}
            >
              <div className={cn("flex flex-row items-center pl-4 pt-4")}>
                <div className="min-w-fit text-sm font-medium  self-start text-[#333333]">
                  Test {idx + 1}:
                </div>
                <Textarea
                  className="text-[#121212] !border-0 font-light pt-0 self-start bg-white focus-within:text-[#000000] "
                  classNames={{
                    base:
                      "shadow-none border-none !border-0 rounded-none truncate  pt-0",
                    inputWrapper: ` rounded-md  !border-0 ${
                      false ? "border-none px-4" : ""
                    }  ${false ? "text-[#000000]" : "text-[#333333]"} pt-0`,
                    input: "!text-black !border-0 pt-0",
                  }}
                  maxRows={9999999}
                  minRows={1}
                  variant="bordered"
                  labelPlacement="outside"
                  value={value.test}
                  placeholder={"add Text"}
                  onChange={(e) => {
                    handleChange("test", e.target.value, idx);
                  }}
                  onMouseDown={(e) => {
                    const textarea = e.target as HTMLTextAreaElement;
                    const clickPosition = textarea.selectionStart;
                    textarea.focus();
                    textarea.setSelectionRange(clickPosition, clickPosition);
                  }}
                />
              </div>
              <div
                className={cn(
                  "flex flex-row items-center p-4",
                  expandedView && "p-0 pl-4"
                )}
              >
                <div className="min-w-fit text-sm font-medium  self-start text-[#333333]">
                  Result:
                </div>
                <Textarea
                  className="text-[#121212] !border-0 font-light pt-0 self-start bg-white focus-within:text-[#000000] "
                  classNames={{
                    base:
                      "shadow-none border-none !border-0 rounded-none truncate pt-0",
                    inputWrapper: ` rounded-md  !border-0 ${
                      false ? "border-none px-4" : ""
                    }  ${false ? "text-[#000000]" : "text-[#333333]"} pt-0`,
                    input: "!text-black !border-0 pt-0",
                  }}
                  maxRows={9999999}
                  minRows={1}
                  variant="bordered"
                  labelPlacement="outside"
                  value={value.result}
                  placeholder={"add Text"}
                  onChange={(e) => {
                    handleChange("result", e.target.value, idx);
                  }}
                  onMouseDown={(e) => {
                    const textarea = e.target as HTMLTextAreaElement;
                    const clickPosition = textarea.selectionStart;
                    textarea.focus();
                    textarea.setSelectionRange(clickPosition, clickPosition);
                  }}
                />
              </div>
              <div>
                {/* <InlineSources
                sources={value.sources}
                onSourceClick={onSourceClick}
                handleSourceRemoveAtIndex={handleSourceRemoveAtIndex}
                tooltipPlacement={expandedView ? "bottom" : "bottom"}
              /> */}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default TestResults;
