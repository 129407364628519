import { AgentSessionType } from "@/modules/sessions/types";
import { UploadedFile } from "@/shared/types/file-upload";
import { NavigateFunction } from "react-router-dom";
import { AGENT_TYPES, AgentData, GapAssessmentTypes, MultiVendorAssessmentTypes, QuestionnaireTypes, RiskAssessmentTypes } from "../../types";
import { processFileForDoraGapAssessment } from "../../use-cases/dora.use-case";
import { processFilesForMapping } from "../../use-cases/quesitionnaire.use-case";
import { processFileForRiskAssessment } from "../../use-cases/risk.use-case";
import { processFileForSoc2GapAssessment } from "../../use-cases/soc2.use-case";
import { processDataForVendorAssessment } from "../../use-cases/vendor.use-case";

export const processDataBasedOnAssessment = async (type: string | undefined, allFiles: UploadedFile[], navigate: NavigateFunction, inputs?: { [key: string]: string }, selectedDropdownOption?: string | undefined, isToggleSelected?: boolean, reassessOption?: string, agentData?: AgentData<AGENT_TYPES, RiskAssessmentTypes | GapAssessmentTypes | QuestionnaireTypes | MultiVendorAssessmentTypes> | undefined, prevSourceUrls?: string[]) => {

  switch (type) {
    case AGENT_TYPES.QUESTIONNAIRE: {
      const questionnaireFile = allFiles.filter(
        (file) => file.type === "main"
      );
      const kbFiles = allFiles.filter((file) => file.type === "kb");
      await processFilesForMapping({
        name: inputs?.title || '',
        questionnaireFile: {
          file: questionnaireFile[0].file,
          url: questionnaireFile[0].signedUrl ?? "",
        },
        sourceFiles: kbFiles.map((file) => {
          return {
            fileName: file.file.name,
            url: file.signedUrl ?? "",
          };
        }),
        navigate,
      });
      return;
    }
    case AGENT_TYPES.RISK_ASSESSMENT: {
      await processFileForRiskAssessment({
        name: inputs?.title || '',
        reassessOption,
        agentDataCurrent: agentData,
        prevSourceUrls,
        sourceFiles: allFiles.map((file) => {
          return {
            fileName: file.file.name ?? "",
            url: file.signedUrl ?? "",
            fileType: file.file.type,
          };
        }),
        navigate,
      });
      return;
    }
    case AGENT_TYPES.GAP_ASSESSMENT:
      switch (selectedDropdownOption) {
        case GapAssessmentTypes.DORA:
          await processFileForDoraGapAssessment({
            name: inputs?.title || '',
            sourceFiles: allFiles.map((file) => {
              return {
                fileName: file.file.name ?? "",
                url: file.signedUrl ?? "",
                fileType: file.file.type,
              };
            }),
            navigate,
          });
          break;
        case GapAssessmentTypes.SOC2_TYPE1:
          await processFileForSoc2GapAssessment({
            name: inputs?.title || '',
            customize: isToggleSelected || false,
            sessionType: AgentSessionType.SOC2_GAP_TYPE1,
            sourceFiles: allFiles.map((file) => {
              return {
                fileName: file.file.name ?? "",
                url: file.signedUrl ?? "",
                fileType: file.file.type,
              };
            }),
            navigate,
          });
          break;
        case GapAssessmentTypes.SOC2:
        case GapAssessmentTypes.SOC2_TYPE2:
          await processFileForSoc2GapAssessment({
            customize: isToggleSelected || false,
            sessionType: AgentSessionType.SOC2_GAP_TYPE2,
            name: inputs?.title || '',
            sourceFiles: allFiles.map((file) => {
              return {
                fileName: file.file.name ?? "",
                url: file.signedUrl ?? "",
                fileType: file.file.type,
              };
            }),
            navigate,
          });
          break;
        default:
          throw new Error("Invalid gap assessment type");
      }
      return;
    case AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT:
      await processDataForVendorAssessment({
        name: inputs?.title || '',
        multi_file_vendor_assessment: {
          vendor_name: inputs?.vendorName || '',
          scope_of_integration: inputs?.scope || '',
        },
        sourceFiles: allFiles.map((file) => {
          return {
            fileName: file.file.name,
            url: file.signedUrl ?? "",
            fileType: file.file.type,
          };
        }),
        navigate,
      });
      return;
    default:
      throw new Error("Invalid agent type : " + type);
  }
}