import { AgentSessionStatus } from "@/modules/sessions/types";
import { Spinner } from "@nextui-org/react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { helper } from "../../constants/helpers";
import { getAgentData, getAgentTypeFromId } from "../../states";

type ProcessData = {
  breadcrumb: string;
  title?: string;
  subtitle?: string;
};

const Intermediate = () => {
  const { id } = useParams<{ id: string }>();

  const processData = useMemo<ProcessData | undefined>(() => {
    if (!id) return undefined;

    const { agentType, agentSubType } = getAgentTypeFromId(id);

    if (!agentType) return undefined;

    const agentData = getAgentData<typeof agentType, typeof agentSubType>(id);
    if (!agentData) return undefined;

    const currentStep = agentData.stepData.find(
      (step) =>
        step.status === AgentSessionStatus.IN_PROGRESS ||
        step.status === AgentSessionStatus.INPUT_NEEDED
    );

    const process = currentStep?.type;
    if (!process) return undefined;

    return helper[agentSubType].process[process];
  }, [id]);

  return (
    <div className="bg-white h-full w-full flex items-center justify-center px-4 py-4">
      <div className="flex flex-col items-center gap-3">
        <Spinner size="lg" />
        <h2 className="text-lg">{processData?.title}</h2>
        <h6 className="text-[#71717A] text-sm">{processData?.subtitle}</h6>
      </div>
    </div>
  );
};

export default Intermediate;
