import CircleTickHalf from "@/shared/icons/circle-check-half";
import CircleTick from "@/shared/icons/circle-tick";
import CircleWarning from "@/shared/icons/circle-warning";
import DoubleTickIcon from "@/shared/icons/double-tick";
import capitalizeFirstLetter from "@/shared/utils/capitalize-first-letter";
import { cn } from "@/shared/utils/classname-merger";
import { Avatar, AvatarGroup, Tooltip } from "@nextui-org/react";
import { confidenceHelper, getRiskValueStyle } from "../../constants/helpers";
import { RenderCellTypeEnum } from "../../constants/helpers";
import {
  ConfidenceTypes,
  PDFReviewSource,
  ReviewSourceTypes,
} from "../../types/index.ts";
import { cleanConfidence } from "../../utils/clean-confidence";
import { getTagColor } from "../../utils/risk-control";
import { CircleX } from "lucide-react";

interface RenderTableComponentsProps {
  renderType: RenderCellTypeEnum;
  data: any;
  metaData: {
    approved: string[];
    edited: string[];
    agentId: string;
  };
  onClickAvatar: (rowId: string, index: number) => void;
  fieldName?: string;
}

// @param compliant: string
// return object
// {
//   icon: ReactNode;
//   style: string;
// }
const getCompliantIcon = (compliant: string) => {
  const fullyCompliant = {
    icon: <CircleTick className="h-4 w-4" />,
    style: "bg-[#D1F4E0]",
    shouldRender: true,
  };
  const partiallyCompliant = {
    icon: <CircleTickHalf className="h-4 w-4" />,
    style: "bg-[#F5A524] bg-opacity-20",
    shouldRender: true,
  };
  const notCompliant = {
    icon: <CircleWarning className="h-4 w-4" />,
    style: "bg-[#FDD0DF]",
    shouldRender: true,
  };
  const dataNotFound = {
    icon: <CircleX className="h-4 w-4" color="#71717A" />,
    style: "bg-[#F4F4F5]",
    shouldRender: true,
  };

  const compliantStatus = compliant?.toLowerCase().replace(/-/g, " ");

  const statusToIcon = {
    "fully compliant": fullyCompliant,
    "partially compliant": partiallyCompliant,
    "non compliant": notCompliant,
    "data not found": dataNotFound,
    "exception noted": notCompliant,
    "no exceptions noted": fullyCompliant,
    compliant: fullyCompliant, //for backwards compatibility
  };

  if (Object.keys(statusToIcon).includes(compliantStatus)) {
    return statusToIcon[compliantStatus as keyof typeof statusToIcon];
  }

  return {
    icon: <span>NA</span>,
    style: "bg-[#F4F4F5]",
    shouldRender: false,
  };
};

export const renderTableComponents = ({
  renderType,
  data,
  metaData,
  onClickAvatar,
  fieldName,
}: RenderTableComponentsProps) => {
  const { approved, edited } = metaData;

  switch (renderType) {
    case RenderCellTypeEnum.COMPLIANT: {
      const { icon, style, shouldRender } = getCompliantIcon(
        data.compliant as string
      );
      return (
        shouldRender && (
          <Tooltip
            content={capitalizeFirstLetter(
              `${data.compliant.replace("-", " ")}`
            )}
            classNames={{
              content:
                "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
              arrow: "bg-black bg-opacity-80 backdrop-blur-md",
            }}
          >
            <div
              className={cn("w-fit h-fit p-[6px] rounded-xl mx-auto", style)}
            >
              {icon}
            </div>
          </Tooltip>
        )
      );
    }
    case RenderCellTypeEnum.TAG:
      return (
        <p
          className={cn(
            "text-[#333333] whitespace-nowrap grow w-fit max-w-fit min-w-fit bg-gray-100 rounded-xl text-[12px] leading-4 px-3 py-1 ",
            getTagColor((data.tag as string) ?? "")
          )}
        >
          {data.tag}
        </p>
      );
    case RenderCellTypeEnum.RISK_STRING:
      return (
        <p className="text-[#333]">
          {data.riskString || data.question || data.requirement || data.control}
        </p>
      );
    case RenderCellTypeEnum.VENDOR_QUESTION:
      return (
        <p className="text-[#333]">
          <span className="font-medium pr-1">{data.questionKey}:</span>
          {data.question}
        </p>
      );
    case RenderCellTypeEnum.RISK_LEVEL: {
      const style = getRiskValueStyle(data.riskValue as number);
      return (
        <div className="flex justify-center items-center">
          <p
            className={cn(
              "text-center rounded-xl w-fit px-3 text-[12px]  leading-4	 py-1",
              style
            )}
          >
            {data.riskValue}
          </p>
        </div>
      );
    }
    case RenderCellTypeEnum.CONFIDENCE:
      if (data.confidence === ConfidenceTypes.DNF) return null;
      return (
        <div className="flex justify-center items-center">
          <Tooltip
            content={capitalizeFirstLetter(
              `${cleanConfidence(
                data.confidence as ConfidenceTypes
              )} confidence`
            )}
            classNames={{
              content:
                "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
              arrow: "bg-black bg-opacity-80 backdrop-blur-md",
            }}
          >
            <span
              className="rounded min-w-2 min-h-2 inline-block"
              style={{
                background:
                  confidenceHelper[
                    cleanConfidence(data.confidence as ConfidenceTypes)
                  ]?.color,
              }}
            />
          </Tooltip>
        </div>
      );
    case RenderCellTypeEnum.SOURCE:
      return (
        <div className="flex w-full  flex-row gap-x-1 ">
          <AvatarGroup
            isBordered
            classNames={{
              count:
                "flex relative justify-center items-center box-border overflow-hidden align-middle z-0 outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 text-primary-foreground rounded-full ring-0 bg-[#006FEE] bg-opacity-20 text-primary ring-offset-2 ring-offset-background dark:ring-offset-background-dark -ms-0 data-[hover=true]:-translate-x-1 rtl:data-[hover=true]:translate-x-1 transition-transform data-[focus-visible=true]:-translate-x-1 rtl:data-[focus-visible=true]:translate-x-1 ring-primary w-4 h-4 text-tiny",
            }}
            color="primary"
          >
            {(data.sources as ReviewSourceTypes[])
              ?.slice(0, 4)
              .map((source, index) => {
                return (
                  <Tooltip
                    showArrow={true}
                    placement="top-end"
                    key={`${index}_${source.source_number}`}
                    classNames={{
                      content:
                        "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny p-3",
                      arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                      base: "before:bg-black/80", // This targets the pseudo-element used for the arrow
                    }}
                    content={
                      <div className="flex flex-col gap-y-[0.5rem] max-w-96">
                        <div className="max-h-[5rem] min-h-[3rem] overflow-hidden">
                          <p className="line-clamp-4 text-white">
                            {source.source_text ? source.source_text : null}
                          </p>
                        </div>
                        <div className="flex gap-3">
                          <p className="text-tiny text-[#A5A5A5] text-ellipsis">
                            {source.source_file_name || source.file_name}
                          </p>
                          {((source as PDFReviewSource).source_page_number ||
                            (source as PDFReviewSource).page_number) && (
                            <>
                              <span className="text-tiny text-[#A5A5A5]">
                                •
                              </span>
                              <p className="text-tiny text-[#A5A5A5]">
                                Page{" "}
                                {(source as PDFReviewSource)
                                  .source_page_number ||
                                  (source as PDFReviewSource).page_number}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    }
                  >
                    <Avatar
                      classNames={{
                        base:
                          "flex relative justify-center items-center box-border overflow-hidden align-middle z-0 outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 text-primary-foreground rounded-full ring-0 bg-[#006FEE] bg-opacity-20 text-primary ring-offset-2 ring-offset-background dark:ring-offset-background-dark -ms-0 data-[hover=true]:-translate-x-1 rtl:data-[hover=true]:translate-x-1 transition-transform data-[focus-visible=true]:-translate-x-1 rtl:data-[focus-visible=true]:translate-x-1 ring-primary w-4 h-4 text-[10px]",
                        icon: "[&>span]:text-primary",
                      }}
                      icon={<span>{index + 1}</span>}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        onClickAvatar(data.id as string, index);
                      }}
                    >
                      {index}
                    </Avatar>
                  </Tooltip>
                );
              })}
          </AvatarGroup>
          {data.sources?.length > 4 && (
            <p
              className="text-[#006FEE] text-[10px] font-medium"
              onClick={(e) => {
                e.stopPropagation();
                onClickAvatar(data.id as string, data.sources?.length - 4);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                onClickAvatar(data.id as string, data.sources?.length - 4);
              }}
            >
              +{data.sources?.length - 4}
            </p>
          )}
        </div>
      );
    case RenderCellTypeEnum.STATUS: {
      const isApproved = approved.includes(data.id as string);
      const isEdited = edited.includes(data.id as string);
      if (isApproved) {
        return (
          <span className="text-[#71717A] text-tiny flex items-center  ml-4 mr-6">
            <DoubleTickIcon />
            Accepted
          </span>
        );
        // biome-ignore lint/style/noUselessElse: <this is not a useless else>
      } else if (isEdited) {
        return (
          <span className="text-[#71717A] text-tiny flex items-center  ml-4 mr-6">
            Edited
          </span>
        );
        // biome-ignore lint/style/noUselessElse: <this is not a useless else>
      } else {
        return null;
      }
    }

    case RenderCellTypeEnum.STRING:
      if (fieldName && fieldName in data) {
        return <div className="my-4">{data[fieldName]}</div>;
      }
      return null;

    default:
      return null;
  }
};
