import AlertIcon from "@/shared/icons/alert";
import ClipboardListIcon from "@/shared/icons/clipboard-list";
import EyeClosedIcon from "@/shared/icons/eye-closed";
import GapAssessmentIcon from "@/shared/icons/gap-assessment";
import ShieldQuestionMarkIcon from "@/shared/icons/shield-question";
import { AGENT_TYPES } from "../types/index.ts";

export const assessments = [
  {
    key: AGENT_TYPES.RISK_ASSESSMENT,
    label: "Risk Assessment",
    desc: "Zania analysis evidence to generate risk assessment reports.",
    icon: AlertIcon,
    isComponentReady: true,
  },
  {
    key: AGENT_TYPES.QUESTIONNAIRE,
    label: "Security Questionnaire",
    desc: "Zania uses your knowledge base to auto-fill and refine security questionnaires.",
    icon: ClipboardListIcon,
    isComponentReady: true,
  },
  {
    label: "Third-party Risk Assessment",
    key: AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    desc: "Zania will use AI to conduct comprehensive vendor evaluations",
    icon: ShieldQuestionMarkIcon,
    isComponentReady: true,
  },
  {
    label: "Design Review",
    key: "design_review",
    desc: "Zania identifies privacy and security issues, and performs detailed threat modeling.",
    icon: EyeClosedIcon,
    isComponentReady: false,
  },
  {
    label: "Gap assessment",
    key: AGENT_TYPES.GAP_ASSESSMENT,
    desc: "Zania will compile evidences required to conduct gap assessment.",
    icon: GapAssessmentIcon,
    isComponentReady: true,
  },
];
