import { TeamRole } from "@/modules/team/types";
import { useStytchMember } from "@stytch/react/b2b";

const getPermission = (roles: string[], permission: TeamRole) => {
  return {
    roleId: permission,
    hasAccess: roles.includes(permission),
  };
};

export const useUserPermissions = () => {
  const { member } = useStytchMember();

  if (!member) return null;

  const { roles } = member;
  const roleIds = roles.map(({ role_id }) => role_id);

  return {
    canAccessAccuracyResponse: getPermission(
      roleIds,
      TeamRole.RESPONSE_QUALITY_ACCURACY,
    ),
    canAccessSpeedResponse: getPermission(
      roleIds,
      TeamRole.RESPONSE_QUALITY_SPEED,
    ),
  };
};
