import { useAuthActions } from "@/modules/auth/states";
import ZAvatarGradient from "@/shared/components/avatarGradient";
import { ZDivider } from "@/shared/components/divider";
import DoubleArrowIcon from "@/shared/icons/double-left";
import ZaniaIcon from "@/shared/icons/zania";
import ZaniaExpandedIcon from "@/shared/icons/zania-expanded";
import { useStytch } from "@/shared/states/stytch";
import { cn } from "@/shared/utils/classname-merger";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { useStytchMember } from "@stytch/react/b2b";
import { useStytchOrganization } from "@stytch/react/b2b";
import { motion } from "framer-motion";
import { Ellipsis } from "lucide-react";
import { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { sidebarItems } from "../../constants/sidebar";
import NavigationMenu from "./menu-item";

import { shouldEnableInsights } from "@/main";
import LogoutIcon from "@/shared/icons/logout";
import Bugsnag from "@bugsnag/js";

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
}
interface IdentifyArgs {
  userId: string;
  email: string;
  name: string;
}
const identifyToBugsnag = ({ userId, email, name }: IdentifyArgs) => {
  if (!shouldEnableInsights()) {
    return;
  }
  Bugsnag.setUser(userId, email, name);
};

const Sidebar = ({ isOpen, onToggle }: SidebarProps) => {
  const stytch = useStytch();
  const stytchMember = useStytchMember();
  const stytchOrganization = useStytchOrganization();

  const { logout } = useAuthActions();
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const handleLogout = useCallback(async () => {
    await stytch.session.revoke();
    logout();
  }, [logout, stytch.session]);

  identifyToBugsnag({
    userId: stytchMember?.member?.member_id ?? "",
    email: stytchMember?.member?.email_address ?? "",
    name: stytchMember?.member?.name ?? "",
  });

  return (
    <motion.div
      className="relative bg-white bg-opacity-50 h-full flex flex-col items-center justify-between text-white"
      ref={sidebarRef}
      initial={isOpen ? "open" : "closed"}
      animate={isOpen ? "open" : "closed"}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="flex-1 w-full">
        <Link to={"/" + sidebarItems[0].path}>
          <div
            className={cn(
              "p-6 flex items-center gap-4",
              !isOpen && "justify-center",
            )}
          >
            {!isOpen ? (
              <ZaniaIcon className="w-6 h-6" />
            ) : (
              <ZaniaExpandedIcon className="h-6" />
            )}
          </div>
        </Link>
        <NavigationMenu isCollapsed={!isOpen} />
      </div>

      <div className="w-full px-4 py-2">
        <div
          className={cn(
            "flex items-center justify-between w-full flex-col mb-2",
            isOpen && "justify-center",
          )}
        >
          <div
            className={cn(
              "flex w-full items-center justify-between",
              !isOpen && "justify-center",
            )}
          >
            {isOpen ? (
              <div className="flex items-center ">
                <div className="flex items-center  gap-3 ">
                  <ZAvatarGradient
                    name={stytchMember.member?.name ?? ""}
                    textSize={14}
                    size={32}
                  />

                  <div className="flex flex-col w-full">
                    <p className="text-[#09090B] font-medium text-sm truncate">
                      {stytchMember.member?.name}
                    </p>

                    <p className="text-[#71717A] text-xs line-clamp-1">
                      {stytchOrganization.organization?.organization_name}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            <Dropdown className="m-2">
              <DropdownTrigger>
                <Button
                  isIconOnly
                  className="bg-transparent hover:bg-white hover:bg-opacity-30 pb-4"
                >
                  <Ellipsis className="h-5 w-5" color="#52525B" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                {/* <DropdownItem className={cn('px-0.5 py-0.5')}>
                    <div className="flex items-center gap-x-2.5 px-1 py-1">
                      <OrganizationIcon />
                      <p>Switch Organization</p>
                    </div>
                  </DropdownItem> */}
                <DropdownItem
                  onClick={() => void handleLogout()}
                  key={"logout"}
                  className={cn("px-0.5 py-0.5 ")}
                >
                  <div className="flex items-center gap-x-2.5 px-1 py-1">
                    <LogoutIcon />
                    <p>Logout</p>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        <ZDivider />

        <div className="flex items-center justify-center py-2 px-2 w-full text-[#7E7E86]">
          <span className="text-xs">v0.1</span>
        </div>
      </div>

      <span
        className="w-5 z-50 h-5 flex items-center justify-center bg-[#ffffff] bg-opacity-50 border border-[#E4E4E7] rounded-[8px] absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2 cursor-pointer"
        onClick={onToggle}
      >
        <DoubleArrowIcon
          className={cn(!isOpen && "rotate-180")}
          stroke="#7A788E"
        />
      </span>
    </motion.div>
  );
};

export default Sidebar;
