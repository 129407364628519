import { Input, Textarea } from "@nextui-org/react";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { convertSnakeToTitleCase } from "../../utils/snake-to-capital";

type EditableCardProps = {
  text: string;
  title?: string;
  onChange?: (text: string) => string | void;
  isNumber?: boolean;
  noBorder?: boolean;
  maxRows?: number;
  minRows?: number;
  isDisabled?: boolean;
  showTitle?: boolean;
  placeholder?: string;
};

const AgentEditField: FC<EditableCardProps> = ({
  text,
  isNumber = false,
  onChange,
  title,
  noBorder,
  maxRows,
  minRows,
  isDisabled,
  showTitle = true,
  placeholder,
}) => {
  const [value, setValue] = useState(text);
  const [isActive, setIsActive] = useState(false);

  const placeholderString = useMemo(() => {
    return placeholder ?? "N/A";
  }, [placeholder]);

  useEffect(() => {
    if (text !== value) {
      setValue(text);
    }
  }, [text]);

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newValue = e.target.value;
    if (isNumber && newValue !== "") {
      newValue = newValue.replace(/\D/g, "");
    }
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className="relative">
      {title && showTitle && (
        <div
          className="bg-[#F9F7F7] text-[#717171] font-medium py-2 px-4 text-[10px] leading-4"
          style={{
            border: "1px solid #E4E4E7",
            borderLeft: "0px",
            borderRight: "0px",
          }}
        >
          {title}
        </div>
      )}
      {isNumber ? (
        <Input
          className="text-[#121212] font-light bg-white focus-within:text-[#000000] "
          classNames={{
            base: "shadow-none border-none rounded-none truncate ",
            inputWrapper: `border-[1px] rounded-md  ${isActive ? "text-[#000000]" : "text-[#333333]"}`,
            input: "!text-black",
          }}
          variant="bordered"
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          labelPlacement="outside"
          value={value}
          type="text"
          inputMode="numeric"
          pattern="[1-5]"
          placeholder={placeholderString}
          onChange={handleOnChange}
          autoFocus
          disabled={isDisabled}
        />
      ) : (
        <Textarea
          className="text-[#121212] font-light bg-white focus-within:text-[#000000] "
          classNames={{
            base: "shadow-none border-none rounded-none truncate",
            inputWrapper: `border-[1px] rounded-md ${noBorder ? "border-none px-4" : ""}  ${isActive ? "text-[#000000]" : "text-[#333333]"}`,
            input: "!text-black",
          }}
          maxRows={maxRows}
          minRows={minRows}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          variant="bordered"
          labelPlacement="outside"
          value={value}
          placeholder={placeholderString}
          onChange={handleOnChange}
          autoFocus
          disabled={isDisabled}
        />
      )}
    </div>
  );
};

export default AgentEditField;
