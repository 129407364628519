import { AgentSessionStepType } from "@/modules/sessions/types";
import AcceptAndRejectPopOver from "@/shared/components/generate-with-ai/AcceptAndRejectPopOver";
import GenerateWithAI from "@/shared/components/generate-with-ai/GenerateWithAI";
import { cn } from "@/shared/utils/classname-merger";
import {
  Checkbox,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { ChevronDown, Minus, PlusIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Control,
  getCustomizeControlsActions,
  getCustomizeControlsAiOpenStates,
  isControlValid,
  SecurityControlCategoryOption,
  TSCABBRIVATIONS,
  TSCENUM,
} from "../../states/cusomize-controls";
import {
  refineControlsDescription,
  refineControlsTestingProcedure,
} from "../../use-cases/customize-control-ai-generate.use-case";
import { ZInputWithOnChangeDelay, ZTextAreaWithOnChangeDelay } from "./helpers";

interface baseProps {
  control: Control;
  searchQuery: string;
  handleChangeDataState: (
    key: keyof Control,
    uId: string,
    newValue: string
  ) => void;
}

interface CustomizeControlIdRendererProps extends baseProps {
  setAccordianOpenState: (key: TSCENUM, newValue: boolean) => void;
  accordianOpenState: {
    [key in TSCENUM]: boolean;
  };
  selectedControls: Set<string>;
  setSelectedControls: (
    newVal: Set<string> | ((prev: Set<string>) => Set<string>)
  ) => void;
  handleAutoSave: () => void;
  getDuplicateErrorMessage: (control: Control) => string | undefined;
}
export const CustomizeControlIdRenderer = ({
  control,
  handleChangeDataState,
  searchQuery,
  setAccordianOpenState,
  accordianOpenState,
  selectedControls,
  setSelectedControls,
  handleAutoSave,
  getDuplicateErrorMessage,
}: CustomizeControlIdRendererProps) => {
  return (
    <div className={cn("flex w-[216px]")}>
      {control.edited_state === "ACCORDIAN" ? (
        <div className="flex gap-2 items-center py-[5px]">
          <div
            className="p-[1px]  rounded-md cursor-pointer"
            onClick={() =>
              setAccordianOpenState(
                control.tsc,
                !accordianOpenState[control.tsc]
              )
            }
            onKeyDown={() =>
              setAccordianOpenState(
                control.tsc,
                !accordianOpenState[control.tsc]
              )
            }
          >
            {accordianOpenState[control.tsc] ? (
              <Minus size={16} color="#A5A5A5" />
            ) : (
              <PlusIcon size={16} color="#A5A5A5" />
            )}
          </div>
          <div className="text-[10px] leading-4 font-medium text-[#71717A] ">
            {control.tsc === TSCENUM.SECURITY
              ? "Security / Common Criteria".toUpperCase()
              : control.tsc.toUpperCase()}{" "}
            ({TSCABBRIVATIONS[control.tsc]})
          </div>
        </div>
      ) : (
        <div className="flex ">
          <Checkbox
            isSelected={selectedControls.has(control.uid)}
            classNames={{
              base: cn("group", !isControlValid(control) && "invisible"),
              wrapper: cn(
                "group-data-[disabled=true]:border-default",
                "after:group-data-[disabled=true]:bg-default-100"
              ),
              icon: "group-data-[disabled=true]:text-default-300",
            }}
            onChange={() => {
              setSelectedControls((prev) => {
                const newValue = prev;

                if (selectedControls.has(control.uid)) {
                  newValue.delete(control.uid);
                } else {
                  newValue.add(control.uid);
                }
                // handleAutoSave();
                return newValue;
              });
            }}
            data-component="z-table-row-select"
            color={"primary"}
          />
          <ZInputWithOnChangeDelay
            id={`${control.uid}tsc_id`}
            aria-label={`${control.uid}tsc_id`}
            classNames={{
              inputWrapper:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none",
              base:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none",
              clearButton:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none",
              description:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none",
              errorMessage:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none",
              helperWrapper:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none",
              innerWrapper:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none",
              input:
                "bg-transparent p-[6px] data-[hover=true]:bg-transparent shadow-none border-none",
              label:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none",
              mainWrapper:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none",
            }}
            startContent={TSCABBRIVATIONS[control.tsc]}
            placeholder="Add ID"
            value={control.tsc_id.replace(TSCABBRIVATIONS[control.tsc], "")}
            inputValidation={(newValue: string) => {
              if (newValue.trim().length === 0) {
                return true;
              }

              const testRegex = /^\d+(\.\d+)*/;
              const consecutivePeriodRegex = /^(?!.*\.\.).*$/;

              return (
                testRegex.test(newValue) &&
                consecutivePeriodRegex.test(newValue)
              );
            }}
            onChange={(e) => {
              // if (true) {
              handleChangeDataState(
                "tsc_id",
                control.uid,
                TSCABBRIVATIONS[control.tsc] + e.target.value.trimStart()
              );
              // }
            }}
            disabled={searchQuery.length > 0}
            errorMessage={getDuplicateErrorMessage(control)}
            autoFocus={false}
          />
        </div>
      )}
    </div>
  );
};

interface CustomizeControlCategoryRendererProps extends baseProps {}
export const CustomizeControlCategoryRenderer = ({
  control,
  handleChangeDataState,
  searchQuery,
}: CustomizeControlCategoryRendererProps) => {
  return (
    <div className="w-[240px] pt-3">
      {control.edited_state !== "ACCORDIAN" && (
        <div>
          <ZSelectCategory
            control={control}
            handleChangeDataState={handleChangeDataState}
            searchQuery={searchQuery}
          />
        </div>
      )}
    </div>
  );
};

interface ZSelectCategoryProps {
  control: Control;
  handleChangeDataState: (
    key: keyof Control,
    uId: string,
    newValue: string
  ) => void;
  searchQuery: string;
}

const ZSelectCategory = ({
  control,
  handleChangeDataState,
  searchQuery,
}: ZSelectCategoryProps) => {
  const [isOpen, setisOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <Dropdown
        // aria-expanded={false}
        isDisabled={control.tsc !== TSCENUM.SECURITY}
        classNames={{
          trigger: cn(
            "!scale-[1]  !opacity-100 p-3 px-[6px]",
            isOpen &&
              "bg-white p-[11px] px-[5px] border-1 border-solid border-[#A5A5A5] rounded-[4px]"
          ),
        }}
        onOpenChange={(isOpenChange) => {
          setisOpen(isOpenChange);
        }}
      >
        <DropdownTrigger className="">
          <div className={cn("flex flex-row items-center justify-between")}>
            <div
              className={`text-ellipsis line-clamp-1 ${
                control.category ? "text-zinc-700" : "text-zinc-400"
              } `}
            >
              {control.category
                ? SecurityControlCategoryOption.find(
                    (c) => c.value === control.category
                  )?.label ?? ""
                : control.tsc !== TSCENUM.SECURITY
                ? "N/A"
                : "Select Category"}
            </div>

            <div>
              {control.tsc === TSCENUM.SECURITY && (
                <ChevronDown className="h-4 w-4" />
              )}
            </div>
          </div>
        </DropdownTrigger>
        <DropdownMenu
          selectedKeys={new Set([control.category])}
          selectionMode="single"
        >
          {SecurityControlCategoryOption.map((cat) => (
            <DropdownItem
              key={cat.value}
              onClick={() => {
                if (cat.value === control.category) {
                  handleChangeDataState("category", control.uid, "");
                } else {
                  handleChangeDataState("category", control.uid, cat.value);
                }
              }}
            >
              {cat.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

interface CustomizeControlDescriptionRendererProps extends baseProps {}
export const CustomizeControlDescriptionRenderer = ({
  control,
  handleChangeDataState,
  searchQuery,
}: CustomizeControlDescriptionRendererProps) => {
  if (control.edited_state === "ACCORDIAN") {
    return null;
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAcceptAndRejectPopover, setShowAcceptAndRejectPopover] = useState<
    false | "SUCCESS" | "ERROR"
  >(false);

  const handleClickForRefineControls = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await refineControlsDescription({
        task: AgentSessionStepType.SOC2_TYPE2,
        controls: [control],
      });

      if (resp.data.controls[0].control) {
        handleChangeDataState(
          "new_control",
          control.uid,
          resp.data.controls[0].control
        );
      }

      setIsLoading(false);
      getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
        (prev) => prev + 1
      );
      setShowAcceptAndRejectPopover("SUCCESS");
    } catch (err) {
      setIsLoading(false);
      getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
        (prev) => prev + 1
      );
      setShowAcceptAndRejectPopover("ERROR");
      throw new Error("Failed to refine control descroiption ");
    }
  }, [control, handleChangeDataState]);

  const handleClickForAcceptRetryRejectButtons = useCallback(
    (action: "CONTINUE" | "DISCARD") => {
      const { setDataState } = getCustomizeControlsActions();

      if (action === "DISCARD") {
        setShowAcceptAndRejectPopover(false);
        getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
          (prev) => prev - 1
        );
        setDataState((prev) => {
          const newValue = [...prev];

          const findIndexOfEditingControl = newValue.findIndex(
            (d) => d.uid === control.uid
          );
          if (findIndexOfEditingControl > -1) {
            newValue[findIndexOfEditingControl].new_control = undefined;
          }

          return newValue;
        });
      }
      if (action === "CONTINUE") {
        if (showAcceptAndRejectPopover === "ERROR") {
          handleClickForRefineControls();
        }
        if (showAcceptAndRejectPopover === "SUCCESS") {
          setDataState((prev) => {
            const newValue = [...prev];

            const findIndexOfEditingControl = newValue.findIndex(
              (d) => d.uid === control.uid
            );
            if (findIndexOfEditingControl > -1) {
              newValue[findIndexOfEditingControl].control =
                newValue[findIndexOfEditingControl].new_control ??
                newValue[findIndexOfEditingControl].control;
              newValue[findIndexOfEditingControl].new_control = undefined;
            }

            return newValue;
          });
          setShowAcceptAndRejectPopover(false);
          getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
            (prev) => prev - 1
          );
        }
      }
    },
    [handleClickForRefineControls, showAcceptAndRejectPopover, control.uid]
  );

  useEffect(() => {
    const el = document.getElementById("customize-control-table");
    if (showAcceptAndRejectPopover) {
      if (el) {
        if (
          getCustomizeControlsAiOpenStates() === 0 ||
          getCustomizeControlsAiOpenStates() === 1
        ) {
          containerRef.current?.scrollIntoView({});
          el.scrollBy({
            top: -250,
          });
        }
        if (el.classList.contains("overflow-auto")) {
          el.classList.remove("overflow-auto");
          el.classList.add("overflow-hidden");
        }
      }
    } else if (getCustomizeControlsAiOpenStates() === 0) {
      if (el) {
        if (el.classList.contains("overflow-hidden")) {
          el.classList.add("overflow-auto");
          el.classList.remove("overflow-hidden");
        }
      }
    }
  }, [showAcceptAndRejectPopover]);

  return (
    <div
      className="h-full min-w-[420px]"
      id={control.control_id}
      ref={containerRef}
    >
      <ZTextAreaWithOnChangeDelay
        id={`${control.uid}control`}
        aria-label={`${control.uid}control`}
        className={cn(
          "text-zinc-700 font-light bg-transparent  focus-within:text-[#000000] mb-[5px]",
          control.new_control !== undefined && "mb-0"
        )}
        classNames={{
          base:
            "shadow-none  border-none hover:border-none rounded-none truncate",
          inputWrapper: ` text-sm font-normal text-zinc-700 p-px ${
            true ? "border-none " : ""
          }  ${
            false ? "text-[#000000]" : "text-zinc-700"
          } border-1 border-[#ECECEC]`,
          input: "p-[4px]",
        }}
        forceActive={isLoading || showAcceptAndRejectPopover !== false}
        strikeContent={
          showAcceptAndRejectPopover !== false
            ? control.new_control !== undefined
            : undefined
        }
        minRows={2}
        maxRows={8}
        disabled={searchQuery.length > 0}
        variant="bordered"
        labelPlacement="outside"
        value={control.control}
        placeholder="Add Control Description"
        onChange={(e) =>
          handleChangeDataState("control", control.uid, e.target.value)
        }
        autoFocus={false}
      />

      {control.new_control !== undefined && (
        <ZTextAreaWithOnChangeDelay
          id={`${control.uid}new_control`}
          aria-label={`${control.uid}new_control`}
          className="text-zinc-700 font-light bg-transparent  focus-within:text-[#000000] mb-[5px]"
          classNames={{
            base:
              "shadow-none  border-none hover:border-none rounded-none truncate",
            inputWrapper: ` text-sm font-normal text-zinc-700 p-px ${
              true ? "border-none " : ""
            }  ${
              false ? "text-[#000000]" : "text-zinc-700"
            } border-0 border-[#ECECEC]`,
            input: "p-[4px]",
          }}
          forceActive={true}
          strikeContent={false}
          minRows={2}
          maxRows={8}
          disabled={searchQuery.length > 0}
          variant="bordered"
          labelPlacement="outside"
          value={control.new_control}
          placeholder="Add Control Description"
          onChange={(e) =>
            handleChangeDataState("new_control", control.uid, e.target.value)
          }
          autoFocus={false}
        />
      )}
      {showAcceptAndRejectPopover && (
        <div className="h-4">
          <AcceptAndRejectPopOver
            isOpen
            type={showAcceptAndRejectPopover}
            handleClickForAcceptRetryRejectButtons={
              handleClickForAcceptRetryRejectButtons
            }
            handleClose={() => setShowAcceptAndRejectPopover(false)}
          />
        </div>
      )}
      {control.control && showAcceptAndRejectPopover === false && (
        <div className="group-hover:h-4  h-0 overflow-hidden ease-in-out duration-200 ">
          <GenerateWithAI
            contextText={control.control}
            existingText={control.control}
            isLoadingAIResponse={isLoading}
            handleClickForRefineControls={handleClickForRefineControls}
          />
        </div>
      )}
    </div>
  );
};

interface CustomizeControlTestingProcedureRendererProps extends baseProps {}
export const CustomizeControlTestingProcedureRenderer = ({
  control,
  handleChangeDataState,
  searchQuery,
}: CustomizeControlTestingProcedureRendererProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAcceptAndRejectPopover, setShowAcceptAndRejectPopover] = useState<
    false | "SUCCESS" | "ERROR"
  >(false);

  const handleClickForRefineControls = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await refineControlsTestingProcedure({
        task: AgentSessionStepType.SOC2_TYPE2,
        controls: [control],
      });

      if (resp.data.controls[0].toe) {
        if (control.toe) {
          handleChangeDataState(
            "new_toe",
            control.uid,
            resp.data.controls[0].toe
          );
          getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
            (prev) => prev + 1
          );
          setShowAcceptAndRejectPopover("SUCCESS");
        } else {
          handleChangeDataState("toe", control.uid, resp.data.controls[0].toe);
        }
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
        (prev) => prev + 1
      );
      setShowAcceptAndRejectPopover("ERROR");
      throw new Error("Failed to refine control descroiption ");
    }
  }, [control, handleChangeDataState]);

  const handleClickForAcceptRetryRejectButtons = useCallback(
    (action: "CONTINUE" | "DISCARD") => {
      const { setDataState } = getCustomizeControlsActions();

      if (action === "DISCARD") {
        setShowAcceptAndRejectPopover(false);
        getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
          (prev) => prev - 1
        );
        setDataState((prev) => {
          const newValue = [...prev];

          const findIndexOfEditingControl = newValue.findIndex(
            (d) => d.uid === control.uid
          );
          if (findIndexOfEditingControl > -1) {
            newValue[findIndexOfEditingControl].new_toe = undefined;
          }

          return newValue;
        });
      }
      if (action === "CONTINUE") {
        if (showAcceptAndRejectPopover === "ERROR") {
          handleClickForRefineControls();
        }
        if (showAcceptAndRejectPopover === "SUCCESS") {
          setDataState((prev) => {
            const newValue = [...prev];

            const findIndexOfEditingControl = newValue.findIndex(
              (d) => d.uid === control.uid
            );
            if (findIndexOfEditingControl > -1) {
              newValue[findIndexOfEditingControl].toe =
                newValue[findIndexOfEditingControl].new_toe ??
                newValue[findIndexOfEditingControl].toe;
              newValue[findIndexOfEditingControl].new_toe = undefined;
            }

            return newValue;
          });
          setShowAcceptAndRejectPopover(false);
          getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
            (prev) => prev - 1
          );
        }
      }
    },
    [handleClickForRefineControls, showAcceptAndRejectPopover, control.uid]
  );

  useEffect(() => {
    const el = document.getElementById("customize-control-table");
    if (showAcceptAndRejectPopover) {
      if (el) {
        if (
          getCustomizeControlsAiOpenStates() === 0 ||
          getCustomizeControlsAiOpenStates() === 1
        ) {
          containerRef.current?.scrollIntoView({});
          el.scrollBy({
            top: -150,
          });
        }
        if (el.classList.contains("overflow-auto")) {
          el.classList.remove("overflow-auto");
          el.classList.add("overflow-hidden");
        }
      }
    } else if (getCustomizeControlsAiOpenStates() === 0) {
      if (el) {
        if (el.classList.contains("overflow-hidden")) {
          el.classList.add("overflow-auto");
          el.classList.remove("overflow-hidden");
        }
      }
    }
  }, [showAcceptAndRejectPopover]);

  if (control.edited_state === "ACCORDIAN") {
    return null;
  }

  return (
    <div className="min-w-[372px]" ref={containerRef}>
      <ZTextAreaWithOnChangeDelay
        id={`${control.uid}toe`}
        aria-label={`${control.uid}toe`}
        className={cn(
          "text-zinc-700 font-light bg-transparent  focus-within:text-[#000000] mb-[5px]",
          control.new_toe !== undefined && "mb-0"
        )}
        classNames={{
          base:
            "shadow-none  border-none hover:border-none rounded-none truncate",
          inputWrapper: ` text-sm font-normal text-zinc-700 p-px  ${
            true ? "border-none " : ""
          }  ${false ? "text-[#000000]" : "text-zinc-700"}`,
          input: "p-[4px]",
        }}
        forceActive={isLoading || showAcceptAndRejectPopover !== false}
        strikeContent={
          showAcceptAndRejectPopover !== false
            ? control.new_toe !== undefined
            : undefined
        }
        minRows={2}
        maxRows={8}
        disabled={searchQuery.length > 0}
        variant="bordered"
        labelPlacement="outside"
        value={control.toe}
        placeholder="Add Testing Procedure"
        onChange={(e) =>
          handleChangeDataState("toe", control.uid, e.target.value)
        }
        autoFocus={false}
      />

      {control.new_toe !== undefined && (
        <ZTextAreaWithOnChangeDelay
          id={`${control.uid}new_toe`}
          aria-label={`${control.uid}new_control`}
          className="text-zinc-700 font-light bg-transparent  focus-within:text-[#000000] mb-[5px]"
          classNames={{
            base:
              "shadow-none  border-none hover:border-none rounded-none truncate",
            inputWrapper: ` text-sm font-normal text-zinc-700 p-px ${
              true ? "border-none " : ""
            }  ${
              false ? "text-[#000000]" : "text-zinc-700"
            } border-0 border-[#ECECEC]`,
            input: "p-[4px]",
          }}
          forceActive={true}
          strikeContent={false}
          minRows={2}
          maxRows={8}
          disabled={searchQuery.length > 0}
          variant="bordered"
          labelPlacement="outside"
          value={control.new_toe}
          placeholder="Add Control Description"
          onChange={(e) =>
            handleChangeDataState("new_toe", control.uid, e.target.value)
          }
          autoFocus={false}
        />
      )}
      {control.control && (
        <div className="group-hover:h-4  h-0 overflow-hidden ease-in-out duration-200 ">
          <GenerateWithAI
            contextText={control.control}
            existingText={control.toe}
            isLoadingAIResponse={isLoading}
            handleClickForRefineControls={handleClickForRefineControls}
          />
        </div>
      )}
      {showAcceptAndRejectPopover && (
        <AcceptAndRejectPopOver
          isOpen
          type={showAcceptAndRejectPopover}
          handleClickForAcceptRetryRejectButtons={
            handleClickForAcceptRetryRejectButtons
          }
          handleClose={() => setShowAcceptAndRejectPopover(false)}
        />
      )}
    </div>
  );
};
